import { useState } from "react";
import "../../sources/Sources.css";
import { useTranslation } from "react-i18next";
import { MenuItem } from "@material-ui/core";
import { AboutServiceModal } from "./AboutServiceModal";

export const AboutService = () => {
  const [openAboutServiceModal, setOpenAboutServiceModal] = useState(false);
  const { t } = useTranslation();

  const handleOpenAboutServiceModal = () => {
    setOpenAboutServiceModal(true);
  };

  const handleCloseAboutServiceModal = () => {
    setOpenAboutServiceModal(false);
  };

  return (
    <div>
      <MenuItem
        className="source-item-style"
        onClick={handleOpenAboutServiceModal}
      >
        {t("TopBar.aboutService")}
      </MenuItem>
      {openAboutServiceModal && (
        <AboutServiceModal
          handleClose={handleCloseAboutServiceModal}
          title={t("AboutServicePage.title")}
          open={openAboutServiceModal}
          aboutService
        />
      )}
    </div>
  );
};
