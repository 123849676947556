import type { VFC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import "./PreviousResultPage.css";
import { ResultCategory } from "../survey/surveyresult/ResultCategory";
import type { PreviousUserResult } from "../../utils/api/apiInterfaces";

const circleSize = 105;
const circleFontSize = 16;

interface Props {
  previousResult: PreviousUserResult;
}

export const PreviousResultPageSurveyResultCard: VFC<Props> = ({
  previousResult: {
    cycleId,
    companyAverage,
    surveyId,
    termId,
    unencryptedAverageDevelopmentResult,
    unencryptedAveragePhysicalResult,
    unencryptedAverageResult,
    unencryptedAverageStressResult,
    userCycleResultFinishedAt,
  },
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      role="button"
      tabIndex={-1}
      className="previous-result-page-result-card-wrapper"
      onKeyPress={() =>
        navigate(`/surveys/result/${surveyId}/${cycleId}/from-overview`)
      }
      onClick={() =>
        navigate(`/surveys/result/${surveyId}/${cycleId}/from-overview`)
      }
    >
      <div className="previous-result-page-summary-wrapper">
        <p className="previous-result-well-being">
          {t("PreviousResultPage.yourWellbeing")}
        </p>
        <p className="previous-result-well-being-average-result">
          {unencryptedAverageResult.toFixed(1)}
        </p>
        <div />
        <p className="previous-result-well-being-company-average-result">
          {t("PreviousResultPage.companyAverage")} {companyAverage.toFixed(1)}
        </p>
      </div>
      <div className="previous-result-information">
        <p className="previous-result-information-header">
          {t("PreviousResultPage.term")} {termId}{" "}
          {userCycleResultFinishedAt.substring(0, 10)}{" "}
        </p>
        <div className="previous-result-information-result-category-wrapper">
          <ResultCategory
            size={circleSize}
            fontSizeCategory={circleFontSize}
            category={t("Categories.development")}
            value={Math.floor(unencryptedAverageDevelopmentResult * 10) / 10}
          />
          <ResultCategory
            size={circleSize}
            fontSizeCategory={circleFontSize}
            category={t("Categories.stress")}
            value={Math.floor(unencryptedAverageStressResult * 10) / 10}
          />
          <ResultCategory
            size={circleSize}
            fontSizeCategory={circleFontSize}
            category={t("Categories.physical")}
            value={Math.floor(unencryptedAveragePhysicalResult * 10) / 10}
          />
        </div>
      </div>
    </div>
  );
};
