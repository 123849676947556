import { CardMedia } from "@material-ui/core";
import type { VFC } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import imagePlaceholder from "../../assets/imagePlaceholder_banner.jpg";
import PlayButtonCircle from "./PlayButtonCircle.svg";
import PlayButtonArrow from "./PlayButtonArrow.svg";
import { getLanguage } from "../../i18n";
import type { ApiResponseMicroCourseResourceWithOrder } from "./MicroCoursePage";

interface Props {
  microCourseResourceWithOrder: ApiResponseMicroCourseResourceWithOrder;
}

export const MicrcCourseResourceCard: VFC<Props> = ({
  microCourseResourceWithOrder,
}) => {
  const lang = getLanguage();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className="micro-course-card-content-wrapper"
      role="button"
      tabIndex={0}
      onClick={() =>
        navigate(
          `/micro-course/micro-course-resource/${microCourseResourceWithOrder.id}`
        )
      }
      onKeyPress={() =>
        navigate(
          `/micro-course/micro-course-resource/${microCourseResourceWithOrder.id}`
        )
      }
    >
      <div className="micro-course-card-button-container">
        {microCourseResourceWithOrder.imageUrl ? (
          <CardMedia
            component="img"
            image={`${
              microCourseResourceWithOrder.imageUrl.split("/image/upload/")[0]
            }/image/upload/w_640,c_limit/${
              microCourseResourceWithOrder.imageUrl.split("/image/upload/")[1]
            }`}
            className="micro-course-card-background-image"
          />
        ) : (
          <CardMedia
            component="img"
            image={imagePlaceholder}
            className="micro-course-card-background-image"
          />
        )}
        <div className="micro-course-play-button-positioner">
          <div className="micro-course-play-button-wrapper">
            <img
              className="micro-course-play-button-circle"
              src={PlayButtonCircle}
              alt="PlayButtonCircle"
            />
            <img
              className="micro-course-play-button-arrow"
              src={PlayButtonArrow}
              alt="PlayButtonArrow"
            />
          </div>
        </div>
      </div>
      <p className="micro-course-resource-card-description">
        {`${
          microCourseResourceWithOrder[
            `name${lang.charAt(0).toUpperCase() + lang.slice(1)}`
          ]
        } ${t("MicroCoursePage.week")} ${microCourseResourceWithOrder.order}`}
      </p>
      <p className="micro-course-resource-card-time-required">
        {microCourseResourceWithOrder.timeRequired}{" "}
        {(microCourseResourceWithOrder.timeRequired || 0) !== 1
          ? t("MicroCoursePage.minutes")
          : t("MicroCoursePage.minute")}
      </p>
    </div>
  );
};
