import ReactDOM from "react-dom";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Suspense } from "react";
import "./index.css";
import HttpsRedirect from "react-https-redirect";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./i18n";

// Use fallback to specify a component used while loading page

ReactDOM.render(
  <Suspense fallback={null}>
    <HttpsRedirect>
      <App />
    </HttpsRedirect>
  </Suspense>,
  document.getElementById("root")
);

// Use the code down below to activate react-dom 18.0.0. At the moment there is a bug though that has to be solved (unmounting bug).
// Wait for next version which fixes it and lift.
/*
const rootElement = document.getElementById("root");
const root = createRoot(rootElement); */
/* root.render(
  <Suspense fallback={null}>
    <HttpsRedirect>
      <App />
    </HttpsRedirect>
  </Suspense>
); */

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
