import React, { VFC, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import Slide from "@material-ui/core/Slide";
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import "../footer/Footer.css";
import type { TransitionProps } from "@material-ui/core/transitions";
import { SourceList } from "./SourceList";
import logo from "../../assets/logo.png";

interface Props {
  open: boolean;
  handleClose: () => void;
  title: string;
  sources?: boolean;
}

export const SourcesModal: VFC<Props> = ({
  open,
  handleClose,
  title,
  sources,
}) => {
  const { t } = useTranslation();
  const Transition = forwardRef(
    (
      props: React.PropsWithChildren<
        TransitionProps & {
          /* eslint-disable  @typescript-eslint/no-explicit-any */
          children?: React.ReactElement<any, any> | undefined;
        }
      >,
      ref
    ) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Slide direction="left" ref={ref} {...props} />
    )
  );
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="source-dialog-wrapper"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography className="alert-dialog-title-style">
          {title}{" "}
          <img
            src={logo}
            alt="dear change."
            data-id="logo"
            className="dc-logo-modal-popup"
          />
        </Typography>
      </DialogTitle>
      <DialogContent>
        {sources && (
          <DialogContentText
            id="alert-dialog-content-style"
            className="alert-dialog-content-style"
          >
            <p>{t("Sources.sourcesHeader")}</p> <br />
            <SourceList />
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          id="footer-modal-close"
          onClick={handleClose}
          color="primary"
          autoFocus
        >
          {t("Footer.modalClose")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
