import type { VFC, CSSProperties } from "react";
import "./SeparatorLine.css";

interface Props {
  style?: CSSProperties;
  className?: string;
}

export const SeparatorLine: VFC<Props> = (props) => (
  <div
    className="separator-line"
    {...props}
    style={{ backgroundColor: "#BDBDBD", height: "2px", width: "100%" }}
  />
);

export const MenuSeparatorLine: VFC = () => (
  <div className="menu-separator-line" />
);
