import { useMemo, VFC } from "react";
import ReactApexChart from "react-apexcharts";
import { useMediaQuery } from "@material-ui/core";
import "./RadialChart.css";
import type { ApexOptions } from "apexcharts";

const colors = {
  green: "rgb(6, 132, 102)",
  yellow: "#FDCF5B",
  red: "rgb(238, 115, 98)",
};

const resolveColorBasedOnValue = (value: string) => {
  if (Number(value) >= 7) return colors.green;
  if (Number(value) > 4) return colors.yellow;
  return colors.red;
};

const resolveLegendOffsetY = (amountOfTerms: number) => {
  if (amountOfTerms === 2) return 36;
  if (amountOfTerms === 3) return 20;

  return 15;
};

const resolveLegendOffsetX = (amountOfTerms: number) => {
  if (amountOfTerms === 2) return 65;
  if (amountOfTerms === 3) return 65;

  return 65;
};

const resolveLegendOffsetYMobile = (amountOfTerms: number) => {
  if (amountOfTerms === 2) return 21;
  if (amountOfTerms === 3) return 12;

  return 5.2;
};

const resolveLegendOffsetXMobile = (amountOfTerms: number) => {
  if (amountOfTerms === 2) return 37.5;
  if (amountOfTerms === 3) return 37.5;

  return 35;
};

const resolveLegendMarginMobile = (amountOfTerms: number) => {
  if (amountOfTerms === 2) return 10;
  if (amountOfTerms === 3) return 6.1;

  return 4;
};

const resolveLegendMargin = (amountOfTerms: number) => {
  if (amountOfTerms === 2) return 15;
  if (amountOfTerms === 3) return 9;

  return 5;
};

const maxValue = 10;

const valueToPercent = (val: string) => (Number(val) * 100) / maxValue;
const valueDiminished = (val: number) => val / 10;

function addTrailingZeros(num: number) {
  const value = valueDiminished(num);
  if (String(value).length !== 1) return String(value);
  return `${value}.0`;
}

interface Props {
  termData: Array<{ name: string; value: string }>;
  category: string;
}

interface State {
  series: Array<number>;
  options: ApexOptions;
}

export const TermCategoryComparisonRadialChart: VFC<Props> = ({
  termData,
  category,
}) => {
  const mobile = useMediaQuery("(max-width: 400px)");
  const names = termData.map((td) => td.name);
  const values = termData.map((td) => td.value);
  const state = useMemo(
    (): State => ({
      series: values.map(valueToPercent),
      options: {
        yaxis: {
          max: 10,
        },
        chart: {
          type: "radialBar",
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          hover: {
            filter: {
              type: "lighten",
              value: 0.1,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0,
            },
          },
        },
        plotOptions: {
          radialBar: {
            inverseOrder: true,
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: "20%",
              background: "transparent",
              image: undefined,
            },
            track: {
              show: true,
              startAngle: undefined,
              endAngle: undefined,
              background: "#E0E0E0",
              strokeWidth: "97%",
              opacity: 1,
              margin: 6,
              dropShadow: {
                enabled: true,
                top: 1,
                left: 1,
                blur: 1,
                opacity: 0.1,
              },
            },
            dataLabels: {
              name: {
                show: true,
                fontSize: "12",
                offsetY: -5,
              },
              value: {
                show: true,
                fontSize: "12",
                offsetY: 6,
                formatter: addTrailingZeros,
              },
            },
          },
        },
        colors: values.map(resolveColorBasedOnValue),
        labels: names,
        legend: {
          show: true,
          floating: true,
          inverseOrder: true,
          fontSize: "14",
          fontFamily: "DM Sans",
          fontWeight: 400,
          position: "left",
          offsetY: resolveLegendOffsetY(termData.length),
          offsetX: resolveLegendOffsetX(termData.length),
          labels: {
            colors: "#4F4F4F",
            useSeriesColors: false,
          },
          markers: {
            width: 0,
            height: 0,
            onClick: undefined,
          },
          formatter: (seriesName: string) => `${seriesName}`,
          itemMargin: {
            vertical: resolveLegendMargin(termData.length),
          },
        },
        responsive: [
          {
            breakpoint: 400,
            options: {
              legend: {
                show: true,
                fontSize: 10,
                offsetY: resolveLegendOffsetYMobile(termData.length),
                offsetX: resolveLegendOffsetXMobile(termData.length),
                itemMargin: {
                  vertical: resolveLegendMarginMobile(termData.length),
                },
              },
              plotOptions: {
                radialBar: {
                  dataLabels: {
                    name: {
                      fontSize: 10,
                    },
                    value: {
                      fontSize: 10,
                      offsetY: 0,
                    },
                  },
                },
              },
            },
          },
        ],
      },
    }),
    [names, termData.length, values]
  );

  return (
    <div className="apex-chart-container">
      <h3>{category}</h3>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="radialBar"
        height={mobile ? 300 : 400}
        width={mobile ? 250 : 350}
      />
    </div>
  );
};
