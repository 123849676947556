import type { VFC } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DownloadPdf from "./DownloadPdf.svg";

interface Props {
  downloadUrl: string;
  name: string;
}

export const MicroCourseResourcePdf: VFC<Props> = ({ downloadUrl, name }) => {
  const { t } = useTranslation();
  return (
    <a
      className="micro-course-resource-pdf-download-tag"
      href={downloadUrl}
      download
      target="_blank"
      rel="noreferrer"
    >
      <div className="micro-course-resource-pdf-icon-text-wrapper">
        <img
          src={DownloadPdf}
          alt="DownloadPdf"
          className="micro-course-resource-pdf-icon"
        />
        <p className="micro-course-resource-pdf-text">{`${t(
          "MicroCoursePage.pdfFirstPart"
        )} "${name}" ${t("MicroCoursePage.pdfLastPart")}`}</p>
      </div>
    </a>
  );
};

MicroCourseResourcePdf.propTypes = {
  downloadUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
