import type { VFC } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import "./PreviousResultPage.css";

export const PreviousResultPageLoadingSkeleton: VFC = () => (
  <Skeleton
    variant="rect"
    width="100%"
    className="previous-result-page-loading-skeleton"
  />
);
