import type { VFC } from "react";
import { getLanguage } from "../../i18n";
import personPlaceholder from "../../assets/personPlaceholder.png";
import "./YourTeamPage.css";
import type { ApiResponseTeacherModel } from "../../utils/api/apiInterfaces";

interface Props {
  teacher: ApiResponseTeacherModel;
}

export const TeacherCard: VFC<Props> = ({ teacher }) => {
  const lang = getLanguage();
  return (
    <div className="teacher-card-wrapper">
      <div className="teacher-card-image-container">
        <img
          alt="teacher"
          src={teacher.imageUrl ? teacher.imageUrl : personPlaceholder}
        />
      </div>
      <div className="teacher-information-container">
        <h4 className="teacher-name-and-title">
          {teacher.name}
          {", "}
          {teacher[`title${lang.charAt(0).toUpperCase() + lang.slice(1)}`]}
        </h4>
        <p className="teacher-information">
          {
            teacher[
              `description${lang.charAt(0).toUpperCase() + lang.slice(1)}`
            ]
          }
        </p>
      </div>
    </div>
  );
};
