import type { CSSProperties, VFC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { CancelOutlined } from "@material-ui/icons";

const useStyles = makeStyles({
  alertBoxWrapper: {
    display: "inline-flex",
    backgroundColor: "#FFBABA",
    alignItems: "center",
    alignSelf: "flex-start",
  },
  cancelOutlined: {
    marginLeft: "5px",
    color: "#D8000C",
  },
  alertBoxParagraph: {
    color: "#D8000C",
    padding: "5px 5px",
    marginRight: "5px",
    wordBreak: "break-all",
  },
});

interface Props {
  style: CSSProperties;
  message: string;
}

export const AlertBox: VFC<Props> = ({ message, style }) => {
  const classes = useStyles();
  return (
    <Box className={classes.alertBoxWrapper} {...{ style }}>
      <CancelOutlined className={classes.cancelOutlined} />
      <Typography className={classes.alertBoxParagraph} variant="body1">
        {message}
      </Typography>
    </Box>
  );
};

AlertBox.propTypes = {
  message: PropTypes.string.isRequired,
};
