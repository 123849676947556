export const passwordValidation = (
  password: string,
  confirmPassword: string
) => {
  const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[\S]{8,}$/;

  if (password === "" || !passwordPattern.test(password)) {
    return {
      validated: false,
      typeof: "password error",
    };
  }

  if (confirmPassword !== password) {
    return {
      validated: false,
      typeof: "confirm password error",
    };
  }

  return { validated: true, typeof: "" };
};
