import "./Footer.css";
import { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { FooterModal } from "./FooterModal";

export const Footer: VFC = () => {
  const [openAboutModal, setOpenAboutModal] = useState(false);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [openIntegrityModal, setOpenIntegrityModal] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();

  const handleOpenAboutModal = () => {
    setOpenAboutModal(true);
  };

  const handleCloseAboutModal = () => {
    setOpenAboutModal(false);
  };

  const handleOpenContactModal = () => {
    setOpenContactModal(true);
  };

  const handleCloseContactModal = () => {
    setOpenContactModal(false);
  };

  const handleOpenIntegrityModal = () => {
    setOpenIntegrityModal(true);
  };

  const handleCloseIntegrityModal = () => {
    setOpenIntegrityModal(false);
  };

  if (location.pathname === "/presentation-page") {
    return null;
  }

  return (
    <div className="footer-wrapper limit-width">
      {!location.pathname.includes("surveys") && (
        <div className="footer-links">
          <button
            type="button"
            className="button-for-modal"
            data-id="about"
            onClick={handleOpenAboutModal}
          >
            {t("Footer.about")}
          </button>
          {openAboutModal && (
            <FooterModal
              open={openAboutModal}
              handleClose={handleCloseAboutModal}
              title={t("Footer.aboutTitle")}
              aboutUs
            />
          )}
          <button
            type="button"
            className="button-for-modal"
            data-id="contact"
            onClick={handleOpenContactModal}
          >
            {t("Footer.contact")}
          </button>
          {openContactModal && (
            <FooterModal
              open={openContactModal}
              handleClose={handleCloseContactModal}
              title={t("Footer.contactTitle")}
              contact
            />
          )}
          <button
            type="button"
            className="button-for-modal"
            data-id="integrity"
            onClick={handleOpenIntegrityModal}
          >
            {t("Footer.integrity")}
          </button>
          {openIntegrityModal && (
            <FooterModal
              open={openIntegrityModal}
              handleClose={handleCloseIntegrityModal}
              title={t("Footer.integrityTitle")}
              generalTerms
            />
          )}
        </div>
      )}
      {!location.pathname.includes("surveys") && (
        <div className="footer-copyright">
          © {new Date().getFullYear()}, dear change.
        </div>
      )}
    </div>
  );
};
