import { useState, VFC } from "react";
import "./Sources.css";
import { useTranslation } from "react-i18next";
import { MenuItem } from "@material-ui/core";
import { SourcesModal } from "./SourcesModal";

export const Sources: VFC = () => {
  const [openSourcesModal, setOpenSourcesModal] = useState(false);
  const { t } = useTranslation();

  const handleOpenSourcesModal = () => {
    setOpenSourcesModal(true);
  };

  const handleCloseAboutModal = () => {
    setOpenSourcesModal(false);
  };

  return (
    <div>
      <MenuItem className="source-item-style" onClick={handleOpenSourcesModal}>
        {t("Sources.sources")}
      </MenuItem>
      {openSourcesModal && (
        <SourcesModal
          handleClose={handleCloseAboutModal}
          title={t("Sources.title")}
          open={openSourcesModal}
          sources
        />
      )}
    </div>
  );
};
