import { useState, VFC } from "react";
import "../../sources/Sources.css";
import { useTranslation } from "react-i18next";
import { MenuItem } from "@material-ui/core";
import { IntegrityModal } from "./IntegrityModal";

export const Integrity: VFC = () => {
  const [openIntegrityModal, setOpenIntegrityModal] = useState(false);
  const { t } = useTranslation();
  const handleOpenIntegrityModal = () => {
    setOpenIntegrityModal(true);
  };
  const handleCloseAboutModal = () => {
    setOpenIntegrityModal(false);
  };
  return (
    <div>
      <MenuItem
        className="source-item-style"
        onClick={handleOpenIntegrityModal}
      >
        {t("TopBar.integrity")}
      </MenuItem>
      {openIntegrityModal && (
        <IntegrityModal
          handleClose={handleCloseAboutModal}
          title={t("Footer.integrityTitle")}
          open={openIntegrityModal}
          generalTerms
        />
      )}
    </div>
  );
};
