import { Paper, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import type { VFC } from "react";
import "./MicroCourseResourcePage.css";

export const MicroCourseResourcePageSkeletonLoading: VFC = () => (
  <Paper elevation={10} className="micro-course-resource-page-paper-container">
    <Typography variant="h3" className="micro-course-resource-page-header">
      <Skeleton variant="text" width="30%" height={60} />
    </Typography>
    <Skeleton variant="rect" width="100%" height="45vw" />
    <div className="micro-course-resource-page-button-container">
      <Skeleton variant="rect" width={200} height={40} />
    </div>
  </Paper>
);
