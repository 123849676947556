import { useCallback, useEffect, useState, VFC } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import "./YourTeamPage.css";
import { Button, Paper, Typography } from "@material-ui/core";
import { ErrorPanelWithReload } from "../shared/components/error-panels/error-panel-with-reload/ErrorPanelWithReload";
import api from "../../utils/api/v1";
import { TeacherCard } from "./TeacherCard";
import { YourTeamPageLoadingSkeleton } from "./YourTeamPageLoadingSkeleton";
import type { ApiResponseTeacherModel } from "../../utils/api/apiInterfaces";

interface State {
  loading: boolean;
  error?: string;
}

export const YourTeamPage: VFC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState<State>({
    loading: false,
    error: undefined,
  });
  const [teachers, setTeachers] = useState<Array<ApiResponseTeacherModel>>([]);

  const getYourTeam = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
      error: undefined,
    }));
    try {
      const { data } = await api.getTeachers();
      setState((prevState) => ({
        ...prevState,
        loading: false,
        error: undefined,
      }));
      setTeachers(data);
    } catch (err) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        error: err instanceof Error ? err.message : "Unknown error",
      }));
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getYourTeam();
    })();
  }, [getYourTeam]);

  return (
    <div className="your-team-page-wrapper">
      <Paper elevation={10} className="your-team-page-paper-container">
        {state.error ? (
          <ErrorPanelWithReload
            apiReloadCall={getYourTeam}
            message={state.error}
          />
        ) : (
          <>
            <Typography variant="h3" className="your-team-page-header">
              {t("YourTeamPage.mainHeader")}
            </Typography>
            <p className="your-team-page-paragraph-text">
              {t("YourTeamPage.informationText")}
            </p>
            <p className="your-team-page-experts-header">
              {t("YourTeamPage.expertsHeader")}
            </p>
            {state.loading && <YourTeamPageLoadingSkeleton />}
            {!state.loading && (
              <>
                {teachers.length < 1 && (
                  <h2 className="previous-result-page-no-finished-surveys">
                    {t("YourTeamPage.noResults")}
                  </h2>
                )}
                {teachers.map((teacher) => (
                  <TeacherCard teacher={teacher} />
                ))}
              </>
            )}
          </>
        )}
        <div className="your-team-page-close-button">
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/library-page")}
          >
            {t("YourTeamPage.closePage")}
          </Button>
        </div>
      </Paper>
    </div>
  );
};
