import type { VFC } from "react";
import "./ResultSummary.css";
import CircleDiagram from "../circleDiagram/CircleDiagram";

const colorForValue = (val: number) => {
  if (val >= 7) return "rgb(6, 132, 102)";
  if (val > 4) return "#FDCF5B";
  return "rgb(238, 115, 98)";
};

interface Props {
  category: string;
  breadText?: string;
  value: number;
  fontSizeCategory: number;
  size: number;
}

export const ResultCategory: VFC<Props> = ({
  category,
  breadText,
  value,
  fontSizeCategory,
  size,
}) => (
  <div className="result-category-wrapper">
    <CircleDiagram
      value={value}
      text={`${value}`}
      color={colorForValue(value)}
      size={size}
      textColor="#535252"
    />
    <h3 style={{ fontSize: fontSizeCategory }} className="dc-heading">
      {category}
    </h3>
    {breadText && <p>{breadText}</p>}
  </div>
);
