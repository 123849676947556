import React, { useEffect, useState, VFC } from "react";
import { Menu, MenuItem, Button, useMediaQuery } from "@material-ui/core";
import { Language } from "@material-ui/icons";
import { setLanguage, getLanguage, i18nLoading } from "../../i18n";

const languages = [
  { value: "en", text: "English" },
  { value: "sv", text: "Svenska" },
];

const LanguagePicker: VFC = () => {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const [currentLanguage, setCurrentLanguage] = useState("");
  const mobile = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    i18nLoading.then(() => setCurrentLanguage(getLanguage() || "sv"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectLanguage = async (language: string) => {
    setCurrentLanguage(language);
    await setLanguage(language);
    handleClose();
  };

  return (
    <div>
      <Button
        className="text language-picker-button"
        data-testid="language_picker_button"
        id="language-picker-button"
        aria-haspopup="true"
        variant="text"
        startIcon={<Language />}
        onClick={handleClick}
      >
        {!mobile ? currentLanguage : ""}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languages.map(({ value, text }) => (
          <MenuItem
            data-testid={value}
            key={value}
            onClick={() => selectLanguage(value)}
          >
            {text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default LanguagePicker;
