import { useState, VFC } from "react";
import "../../sources/Sources.css";
import { useTranslation } from "react-i18next";
import { MenuItem } from "@material-ui/core";
import { AboutUsModal } from "./AboutUsModal";

export const AboutUs: VFC = () => {
  const [openAboutUsModal, setOpenAboutUsModal] = useState(false);
  const { t } = useTranslation();

  const handleOpenAboutUsModal = () => {
    setOpenAboutUsModal(true);
  };

  const handleCloseAboutModal = () => {
    setOpenAboutUsModal(false);
  };

  return (
    <div>
      <MenuItem className="source-item-style" onClick={handleOpenAboutUsModal}>
        {t("TopBar.aboutUs")}
      </MenuItem>
      {openAboutUsModal && (
        <AboutUsModal
          handleClose={handleCloseAboutModal}
          title={t("Footer.aboutTitle")}
          open={openAboutUsModal}
          aboutUs
        />
      )}
    </div>
  );
};
