import { useState, useRef, VFC } from "react";
import { useTranslation } from "react-i18next";
import "./UserSettings.css";
import {
  Button,
  FormControl,
  Modal,
  Paper,
  TextField,
  IconButton,
  Fade,
  Grid,
} from "@material-ui/core";
import { Popper } from "@mui/material";
import { Visibility, VisibilityOff, Check, Close } from "@material-ui/icons";
import api from "../../utils/api/v1";
import { passwordValidation } from "../../utils/validation/passwordValidation";
import { singlePasswordValidation } from "../../utils/validation/singlePasswordValidation";

interface State {
  loading: boolean;
  error?: string;
  showPassword: boolean;
}

interface Form {
  password: string;
  passwordConfirmation: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
}

const textFieldMinWidth = 140;

// eslint-disable-next-line react/prop-types
const ChangePassword: VFC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [form, setForm] = useState<Form>({
    password: "",
    passwordConfirmation: "",
  });
  const [state, setState] = useState<State>({
    loading: false,
    error: undefined,
    showPassword: false,
  });
  const [passwordCorrect, setPasswordCorrect] = useState(false);
  const [confirmPasswordCorrect, setConfirmPasswordCorrect] = useState(false);
  const [showPasswordWarning, setShowPasswordWarning] = useState(false);
  const [showConfirmPasswordWarning, setShowConfirmPasswordWarning] =
    useState(false);
  const [showPasswordRules, setShowPasswordRules] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null | undefined>(null);
  const [windowWidth, setWindowWidth] = useState<number | null>(null);

  const divRef = useRef<HTMLDivElement | null>(null);

  const handleClose = () => onClose && onClose();

  const handleFocus = () => {
    setWindowWidth(window.innerWidth);
    setAnchorEl(divRef.current);
    setShowPasswordRules(true);
  };

  const handleBlur = () => {
    setShowPasswordRules(false);
  };

  const handleChange = ({
    target: { name, value },
  }: {
    target: { name: string; value: string };
  }) => {
    if (name === "password") {
      const validated = singlePasswordValidation(value);
      setPasswordCorrect(validated);
    }

    if (name === "passwordConfirmation") {
      const validated = singlePasswordValidation(value);
      setConfirmPasswordCorrect(validated);
    }

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSave = async () => {
    const validated = passwordValidation(
      form.password,
      form.passwordConfirmation
    );

    if (!validated.validated && validated.typeof === "password error") {
      setShowPasswordWarning(true);
    } else {
      setShowPasswordWarning(false);
    }

    if (!validated.validated && validated.typeof === "confirm password error") {
      setShowConfirmPasswordWarning(true);
    } else {
      setShowConfirmPasswordWarning(false);
    }

    setState({ ...state, loading: true, error: undefined });
    try {
      await api.changePassword(form.password, form.passwordConfirmation);
      handleClose();
    } catch (err) {
      setState({
        ...state,
        loading: false,
        error: err instanceof Error ? err.message : "Unknown error",
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="settings-modal-title"
      aria-describedby="settings-modal-description"
    >
      <Paper className="settings-wrapper">
        <h2 id="settings-modal-title">{t("ChangePassword.title")}</h2>
        <p id="settings-modal-description">{t("ChangePassword.description")}</p>
        <div className="settings-form">
          <FormControl className="password-form-row">
            <Grid container className="change-password-form-control-content">
              <Grid item xs={2}>
                <IconButton
                  tabIndex="-1"
                  className="change-password-icon-style-visibility"
                  onClick={() =>
                    setState({ ...state, showPassword: !state.showPassword })
                  }
                >
                  {state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </Grid>
              <Grid item xs={4} style={{ minWidth: textFieldMinWidth }}>
                <TextField
                  id="password"
                  data-testid="passwordInput"
                  name="password"
                  label={t("ChangePassword.form.password")}
                  type={state.showPassword ? "text" : "password"}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  disabled={state.loading}
                  ref={divRef}
                  inputMode="text"
                  value={form.password}
                />
              </Grid>
              <Grid item xs={1}>
                {passwordCorrect ? (
                  <Check className="correct-pass-style" />
                ) : (
                  <Close className="incorrect-pass-style" />
                )}
              </Grid>
              <Grid item xs={7}>
                {showPasswordWarning && (
                  <p className="change-password-error-paragraph">
                    {t("ResetPasswordPage.validPassword")}
                  </p>
                )}
              </Grid>
              <Popper
                open={showPasswordRules}
                placement={
                  windowWidth && windowWidth <= 500 ? "top" : "right-start"
                }
                anchorEl={anchorEl}
                transition
                className="change-password-popper"
              >
                <Fade in={showPasswordRules} timeout={200}>
                  <Paper className="password-rules-container">
                    <span className="password-rules-arrow" />
                    <p className="password-header-paragraph">
                      {t("ResetPasswordPage.rules.passwordRules")}
                    </p>
                    <p className="password-rules-paragraph">
                      {t("ResetPasswordPage.rules.length")}
                    </p>
                    <p className="password-rules-paragraph">
                      {t("ResetPasswordPage.rules.letter")}
                    </p>
                    <p className="password-rules-paragraph">
                      {t("ResetPasswordPage.rules.numeric")}
                    </p>
                  </Paper>
                </Fade>
              </Popper>
            </Grid>
            <Grid container className="change-password-form-control-content">
              <Grid item xs={2}>
                <IconButton
                  className="change-password-icon-style-check"
                  disabled
                >
                  {form.password.length &&
                  form.password === form.passwordConfirmation ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </IconButton>
              </Grid>
              <Grid item xs={4} style={{ minWidth: textFieldMinWidth }}>
                <TextField
                  data-testid="passwordConformationInput"
                  label={t("ChangePassword.form.passwordConfirmation")}
                  inputMode="text"
                  type={state.showPassword ? "text" : "password"}
                  disabled={state.loading}
                  value={form.passwordConfirmation}
                  name="passwordConfirmation"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={1}>
                {confirmPasswordCorrect ? (
                  <Check className="correct-pass-style" />
                ) : (
                  <Close className="incorrect-pass-style" />
                )}
              </Grid>
              <Grid item xs={7}>
                {showConfirmPasswordWarning && (
                  <p className="change-password-error-paragraph">
                    {t("ResetPasswordPage.noPasswordMatch")}
                  </p>
                )}
              </Grid>
            </Grid>
          </FormControl>
        </div>
        <div className="buttons-modal">
          <Button
            className="buttons-modal-cancel cancel"
            disabled={state.loading}
            variant="outlined"
            onClick={handleClose}
          >
            {t("ChangePassword.cancel")}
          </Button>
          <Button
            data-testid="changePasswordSaveBtn"
            disabled={state.loading}
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            {t("ChangePassword.save")}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

export default ChangePassword;
