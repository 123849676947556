import { useEffect, useState, VFC } from "react";
import { StylesProvider, ThemeProvider } from "@material-ui/core/styles";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import {
  faBook,
  faFilePdf,
  faEdit,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import theme from "./theme";
import { getBrowser } from "./utils/browser/getBrowser";
import Modal from "./components/shared/unsupportedBrowsers/Modal";
import AppRoutes from "./routes/AppRoutes";

library.add(fab, faBook, faFilePdf, faEdit, faSave);

const unsupportedBrowsers = ["IE"];

interface State {
  displayWarning: boolean;
  browserVersion: number;
  browserName?: string;
}

const App: VFC = () => {
  const { t } = useTranslation();
  const [browserState, setBrowserState] = useState<State>({
    displayWarning: false,
    browserVersion: 0,
    browserName: "",
  });

  useEffect(() => {
    const browser = getBrowser();
    const browserName = browser.name;
    const browserVersion = parseFloat(browser.version ?? "");
    const displayWarning = unsupportedBrowsers.includes(String(browserName));

    setBrowserState({
      displayWarning,
      browserName,
      browserVersion,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          {browserState.displayWarning ? (
            <Modal
              show={browserState.displayWarning}
              title={t("UnsupportedBrowser.header")}
              text={t("UnsupportedBrowser.message")}
            />
          ) : (
            <AppRoutes />
          )}
        </StylesProvider>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
