import React, { useCallback, useEffect, useState } from "react";
import "./CalendarInviteModal.css";
import {
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { TimePicker } from "@material-ui/pickers";
import ICalendarLink from "react-icalendar-link";
import PropTypes from "prop-types";
import {
  generateStartAndEndTime,
  generateUntilTime,
} from "./calendar-invite-helpers/CalendarInviteHelper";
import { AlertBox } from "../../shared/components/AlertBox";
import api from "../../../utils/api/v1";

const initialDate = new Date();

export const CalendarInviteModal = ({
  open,
  handleClose,
  accessedFromMenu,
}) => {
  const { t } = useTranslation();
  const [day, setDay] = useState(1);
  const [time, setTime] = useState(initialDate);
  const [state, setState] = useState({
    loading: false,
    error: undefined,
  });
  const [eventData, setEventData] = useState({
    title: "Dear change",
    description: `${t("CalendarInviteModal.eventData.description")}`,
    startTime: generateStartAndEndTime(1, initialDate).startTime,
    endTime: generateStartAndEndTime(1, initialDate).endTime,
    location: `${t("CalendarInviteModal.eventData.location")}`,
    url: `${process.env.REACT_APP_URL}/login`,
  });

  const [calendarUntilTime, setCalendarUntilTime] = useState(
    generateUntilTime()
  );

  const handleTimeChange = (event) => {
    const date = new Date();
    date.setHours(event._d.getHours());
    date.setMinutes(event._d.getMinutes());
    date.setSeconds(0);

    const { startTime, endTime, untilTime } = generateStartAndEndTime(
      day,
      date
    );

    setEventData((prevState) => ({
      ...prevState,
      startTime,
      endTime,
    }));
    setCalendarUntilTime(untilTime);
    setTime(event._d);
  };

  const handleDayChange = (event) => {
    const { startTime, endTime, untilTime } = generateStartAndEndTime(
      event.target.value,
      time
    );
    setEventData((prevState) => ({
      ...prevState,
      startTime,
      endTime,
    }));
    setCalendarUntilTime(untilTime);
    setDay(event.target.value);
  };

  const weekDays = [
    {
      name: `${t("CalendarInviteModal.Monday")}`,
      value: 1,
    },
    {
      name: `${t("CalendarInviteModal.Tuesday")}`,
      value: 2,
    },
    {
      name: `${t("CalendarInviteModal.Wednesday")}`,
      value: 3,
    },
    {
      name: `${t("CalendarInviteModal.Thursday")}`,
      value: 4,
    },
    {
      name: `${t("CalendarInviteModal.Friday")}`,
      value: 5,
    },
    {
      name: `${t("CalendarInviteModal.Saturday")}`,
      value: 6,
    },

    {
      name: `${t("CalendarInviteModal.Sunday")}`,
      value: 7,
    },
  ];

  const setCalendarInviteSent = useCallback(async () => {
    setState({ loading: true, error: undefined });
    try {
      await api.setCalendarInviteSent();
      setState((prevState) => ({ ...prevState, loading: false }));
      handleClose();
    } catch (err) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        error: err.message,
      }));
    }
  }, [handleClose]);

  useEffect(() => {
    const calendarButton = document.querySelector(".dialog-action-wrapper");

    if (calendarButton) {
      calendarButton.addEventListener("click", () => {
        setTimeout(async () => {
          await setCalendarInviteSent();
        }, 150);
      });

      return () => {
        calendarButton.removeEventListener("click", () => {
          setTimeout(async () => {
            await setCalendarInviteSent();
          }, 150);
        });
      };
    }

    return () => null;
  }, [setCalendarInviteSent]);

  return (
    <Dialog open={open} className="dialog-base-style">
      <Box className="dialog-content-wrapper">
        {accessedFromMenu && (
          <div
            tabIndex="-1"
            role="button"
            className="close-calendar-invite-modal"
            onKeyPress={() => handleClose()}
            onClick={() => handleClose()}
          >
            &times;
          </div>
        )}
        <DialogTitle className="dialog-content-title">
          {t("CalendarInviteModal.heading")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="dialog-content-part-one">
            {t("CalendarInviteModal.breadTextPartOne")}
          </DialogContentText>
          <DialogContentText className="dialog-content-part-three">
            {t("CalendarInviteModal.letUsBegin")}
          </DialogContentText>
          <Box className="day-and-time-picker-wrapper">
            <FormControl className="day-picker-form-control" size="small">
              <Select
                labelId="day-select-label"
                id="day-select"
                value={day}
                onChange={handleDayChange}
                variant="outlined"
              >
                {weekDays.map((d) => (
                  <MenuItem key={d.value} value={d.value}>
                    {d.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TimePicker
              value={time}
              ampm={false}
              disableToolbar
              minutesStep={5}
              className="time-picker-styling"
              onChange={handleTimeChange}
              inputVariant="outlined"
              variant="inline"
              size="small"
              autoOk
            />
          </Box>
        </DialogContent>
        <Box className="dialog-action-wrapper-box">
          <DialogActions
            className="dialog-action-wrapper"
            onClick={handleClose}
          >
            <ICalendarLink
              rawContent={calendarUntilTime}
              filename="DearChange calendar invite.ics"
              event={eventData}
              onClick={handleClose}
              className="add-to-calender-button"
            >
              {t("CalendarInviteModal.createInvite")}
            </ICalendarLink>
          </DialogActions>
        </Box>
        {state.error && (
          <Container>
            <AlertBox message={state.error} />
          </Container>
        )}
        <DialogContent>
          <DialogContentText className="dialog-content-part-two">
            {t("CalendarInviteModal.breadTextPartTwo")}
          </DialogContentText>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

CalendarInviteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  accessedFromMenu: PropTypes.bool,
};

CalendarInviteModal.defaultProps = {
  accessedFromMenu: false,
};
