import { Button, Paper, Typography, useMediaQuery } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import type { VFC } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import "./MicroCoursePage.css";

const placeHolderImageArray = [1, 2, 3];
const placeHolderPdfUploadArray = [...placeHolderImageArray];
const placeHolderStarArray = [1, 2, 3, 4, 5];

export const MicroCoursePageSkeleton: VFC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const xxxxxsScreen = useMediaQuery("(max-width: 350px)");
  const xxxxsScreen = useMediaQuery("(max-width: 400px)");
  const xxxsScreen = useMediaQuery("(max-width: 450px)");
  const xxsScreen = useMediaQuery("(max-width: 500px)");
  const xsScreen = useMediaQuery("(max-width: 650px)");
  const sScreen = useMediaQuery("(max-width: 950px)");
  const mScreen = useMediaQuery("(max-width: 1050px)");
  const lScreen = useMediaQuery("(max-width: 1100px)");
  const xlScreen = useMediaQuery("(max-width: 1180px)");
  const xxlScreen = useMediaQuery("(max-width: 1250px)");
  const xxxlScreen = useMediaQuery("(max-width: 1400px)");

  const resolveImagePdfHeight = (isImage: boolean) => {
    if (!isImage) {
      return 180;
    }
    if (xxxxxsScreen) {
      return 140;
    }
    if (xxxxsScreen) {
      return 160;
    }
    if (xxxsScreen) {
      return 180;
    }
    if (xxsScreen) {
      return 200;
    }
    if (xsScreen) {
      return 220;
    }
    if (sScreen) {
      return 240;
    }
    if (mScreen) {
      return 260;
    }
    if (lScreen) {
      return 280;
    }
    if (xlScreen) {
      return 300;
    }
    if (xxlScreen) {
      return 320;
    }
    if (xxxlScreen) {
      return 340;
    }

    return 360;
  };

  return (
    <Paper elevation={10} className="micro-course-page-paper-container">
      <Typography variant="h3" className="resource-page-header">
        <Skeleton variant="text" width="75%" height={80} />
      </Typography>
      <div className="micro-course-card-media-style">
        <Skeleton variant="rect" width="100%" height="100%" />
      </div>
      <div>
        <p className="micro-course-page-text-description">
          <Skeleton variant="rect" width="100%" height={300} />
        </p>
        <div className="micro-course-bottom-content-wrapper">
          <div className="micro-course-image-pdf-wrapper">
            <p className="micro-course-page-resources-header-text">
              <Skeleton
                variant="text"
                width="50%"
                height={60}
                className="micro-course-page-resources-header-text-skeleton"
              />
            </p>
            <div className="micro-course-images-wrapper">
              {placeHolderImageArray.map((item) => (
                <div key={item}>
                  <Skeleton
                    key={item}
                    variant="rect"
                    width="100%"
                    height={resolveImagePdfHeight(true)}
                  />
                  <Skeleton variant="text" width="75%" height={40} />
                  <Skeleton variant="text" width="50%" height={30} />
                </div>
              ))}
            </div>
            <div className="micro-course-pdf-wrapper">
              {placeHolderPdfUploadArray.map((item) => (
                <div key={item}>
                  <Skeleton
                    key={item}
                    variant="rect"
                    width="100%"
                    height={resolveImagePdfHeight(false)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="micro-course-page-button-container">
            <Button
              onClick={() => navigate("/library-page")}
              color="primary"
              variant="contained"
              id="micro-course-navigate-back-to-library"
            >
              {t("ResourcePage.backToLibrary")}
            </Button>
            <div className="micro-course-page-place-holder-stars-wrapper">
              {placeHolderStarArray.map((item) => (
                <Skeleton
                  key={item}
                  variant="circle"
                  width={20}
                  height={20}
                  style={{
                    marginTop: "1rem",
                    marginLeft: "0.25rem",
                    marginRight: "0.25rem",
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Paper>
  );
};
