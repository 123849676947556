import { useEffect, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import "./MicroCourseResourcePage.css";
import { Button, Paper, Typography } from "@material-ui/core";
import { useNavigate, useParams } from "react-router";
import ReactPlayer from "react-player/lazy";
import { getLanguage } from "../../../i18n";
import { ErrorPanelWithReload } from "../../shared/components/error-panels/error-panel-with-reload/ErrorPanelWithReload";
import api from "../../../utils/api/v1";
import { MicroCourseResourcePageSkeletonLoading } from "./MicroCourseResourcePageSkeletonLoading";
import { VideoNotLoadedSkeleton } from "./VideoNotLoadedSkeleton";
import type { ApiResponseMicroCourseResource } from "../../../utils/api/apiInterfaces";
import { MissingRequiredParamError } from "../../shared/components/MissingRequiredParamError";

interface State {
  loading: boolean;
  error?: string;
  microCourseResource: ApiResponseMicroCourseResource | null;
}

export const MicroCourseResourcePage: VFC = () => {
  const { t } = useTranslation();
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [videoSize, setVideoSize] = useState("0%");
  const { microCourseResourceId } = useParams();
  const [state, setState] = useState<State>({
    loading: false,
    error: undefined,
    microCourseResource: null,
  });
  const navigate = useNavigate();
  const lang = getLanguage();

  useEffect(() => {
    (async () => {
      await getMicroCourseResource();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!microCourseResourceId) {
    return (
      <MissingRequiredParamError missingParam="MicroCourseResourceId param is missing" />
    );
  }

  const getMicroCourseResource = async () => {
    setState({
      ...state,
      loading: true,
      error: undefined,
    });
    try {
      const {
        data: { microCourseResource },
      } = await api.getMicroCourseResource(microCourseResourceId);

      setState({ ...state, microCourseResource, loading: false });
    } catch (err) {
      setState({
        ...state,
        loading: false,
        error: err instanceof Error ? err.message : "Unknown error",
      });
    }
  };

  const handleVideoLoaded = () => {
    setIsVideoLoaded(true);
    setVideoSize("100%");
  };

  return (
    <div className="micro-course-resource-page-wrapper">
      {state.error ? (
        <ErrorPanelWithReload
          message={state.error}
          apiReloadCall={getMicroCourseResource}
        />
      ) : state.loading || state.microCourseResource === null ? (
        <MicroCourseResourcePageSkeletonLoading />
      ) : (
        <Paper
          elevation={10}
          className="micro-course-resource-page-paper-container"
        >
          <div>
            <Typography
              variant="h3"
              className="micro-course-resource-page-header"
            >
              {
                state.microCourseResource[
                  `name${lang.charAt(0).toUpperCase() + lang.slice(1)}`
                ]
              }
            </Typography>
            <div className="resource-page-resource-container">
              {lang === "sv" && (
                <div>
                  {state.microCourseResource.resourceVideoUrl ? (
                    <div>
                      {!isVideoLoaded && <VideoNotLoadedSkeleton />}
                      <ReactPlayer
                        url={state.microCourseResource.resourceVideoUrl}
                        controls
                        config={{
                          file: {
                            attributes: {
                              onContextMenu: (e: Event) => e.preventDefault(),
                            },
                          },
                        }}
                        width={videoSize}
                        height={videoSize}
                        progressInterval={1000}
                        playing={false}
                        stopOnUnmount
                        onReady={handleVideoLoaded}
                      />
                    </div>
                  ) : (
                    <h2>{t("MicroCourseResourcePage.videoMissing")}</h2>
                  )}
                </div>
              )}
              {lang === "en" && (
                <div>
                  {state.microCourseResource.resourceVideoUrlEn ? (
                    <div>
                      {!isVideoLoaded && <VideoNotLoadedSkeleton />}
                      <ReactPlayer
                        url={state.microCourseResource.resourceVideoUrlEn}
                        controls
                        config={{
                          file: {
                            attributes: {
                              onContextMenu: (e: Event) => e.preventDefault(),
                            },
                          },
                        }}
                        width={videoSize}
                        height={videoSize}
                        progressInterval={1000}
                        playing={false}
                        onReady={handleVideoLoaded}
                        stopOnUnmount
                      />
                    </div>
                  ) : (
                    <h2>{t("MicroCourseResourcePage.videoMissing")}</h2>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="micro-course-resource-page-button-container">
            <Button
              onClick={() =>
                navigate(
                  `/micro-course-page/${state.microCourseResource?.microCourseId}`
                )
              }
              color="primary"
              variant="contained"
              id="navigate-back-to-micro-course"
              data-testid="navigateBackToMicroCourseBtn"
            >
              {t("MicroCourseResourcePage.backToMicroCourse")}
            </Button>
          </div>
        </Paper>
      )}
    </div>
  );
};
