import jwtDecode from "jwt-decode";
import api from "./api/v1";
import type { Token } from "./httpClient";
import { clearToken, setToken } from "./sessionhandler";

export interface DecodedAccessToken {
  aud: string;
  exp: number;
  iss: string;
  jti: string;
  nbf: number;
  sub: string;
  userId: string;
}

export const decodeToken = (token: string) =>
  jwtDecode<DecodedAccessToken>(token);

export const refreshTokenIfNeeded = async (
  token: Token | null
): Promise<string | null | undefined> => {
  let accessToken = token?.accessToken;
  if (token && token.accessToken && token.refreshToken && token.email) {
    const { exp } = decodeToken(token.accessToken);
    const currentTime = new Date().getTime() / 1000;

    if (currentTime + 300 > exp) {
      try {
        const { data } = await api.refreshToken(
          token.refreshToken,
          token.email
        );

        accessToken = data.accessToken;
        setToken(data);
      } catch {
        clearToken();
        window.location.href = "/login";
      }
    }
  } else {
    clearToken();
    window.location.href = "/login";
  }

  return accessToken;
};
