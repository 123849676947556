import { useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import "./RadialChart.css";
import { TermCategoryComparisonRadialChart } from "./TermCategoryComparisonRadialChart";
import type { PreviousUserResult } from "../../utils/api/apiInterfaces";

interface Props {
  previousUserResults: Array<PreviousUserResult>;
}

export const TermCategoryComparisonRadialChartWrapper: VFC<Props> = ({
  previousUserResults,
}) => {
  const { t } = useTranslation();

  // Only display max 4 terms. Choose the latest ones.
  const lastFourTerms = previousUserResults
    .sort((a, b) => a.termId - b.termId)
    .slice(-4);
  const termDataStress = useMemo(
    () =>
      lastFourTerms.map((pur) => ({
        name: `${t("TermCategoryComparisonRadialChart.term")} ${pur.termId}`,
        value: pur.unencryptedAverageStressResult.toFixed(1),
      })),
    [lastFourTerms, t]
  );
  const termDataPhysical = useMemo(
    () =>
      lastFourTerms.map((pur) => ({
        name: `${t("TermCategoryComparisonRadialChart.term")} ${pur.termId}`,
        value: pur.unencryptedAveragePhysicalResult.toFixed(1),
      })),
    [lastFourTerms, t]
  );
  const termDataDevelopment = useMemo(
    () =>
      lastFourTerms.map((pur) => ({
        name: `${t("TermCategoryComparisonRadialChart.term")} ${pur.termId}`,
        value: pur.unencryptedAverageDevelopmentResult.toFixed(1),
      })),
    [lastFourTerms, t]
  );

  return (
    <div className="radial-chart-heading-charts-wrapper">
      <h2>{t("TermCategoryComparisonRadialChart.betweenTerms")}</h2>
      <div className="radial-chart-wrapper">
        <TermCategoryComparisonRadialChart
          termData={termDataDevelopment}
          category={t("TermCategoryComparisonRadialChart.personalDevelopment")}
        />
        <TermCategoryComparisonRadialChart
          termData={termDataStress}
          category={t("TermCategoryComparisonRadialChart.stressCompetence")}
        />
        <TermCategoryComparisonRadialChart
          termData={termDataPhysical}
          category={t("TermCategoryComparisonRadialChart.physicalActivity")}
        />
      </div>
    </div>
  );
};
