import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

const whitelist = ["sv", "en"];

const langKey = "PORTAL_LANG";
const initialLang = window.localStorage.getItem(langKey) || "sv";

export const i18nLoading = i18n
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // connect with React
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,

    lng: initialLang,
    fallbackLng: "sv",
    whitelist,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

/**
 * Change the portal language
 * @param lang
 * @returns {Promise}
 */
export const setLanguage = (lang) => {
  if (!whitelist.includes(lang)) {
    throw new Error(`Language '${lang}' is not available`);
  }
  window.localStorage.setItem(langKey, lang);
  return i18n.changeLanguage(lang);
};

/**
 * Get the portal language
 * @returns {string}
 */
export const getLanguage = () => i18n.language;

export default i18n;
