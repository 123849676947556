import React from "react";
import globalStateHook from "use-global-hook";
import actions from "../actions";
import { getToken } from "../utils/sessionhandler";

const initialUser = { id: "88c615df-9639-466d-9cc4-7fda8fb3f147" };
let token = getToken();

// This should be refactored with current functionality.
// We use local storage so this internal token state is not used.
if (token) {
  const expires = new Date(token.expirationDate);
  if (expires.getTime() < Date.now()) {
    token = null;
  }
}

const initialState = {
  token: token && token.accessToken,
  user: initialUser,
};

const globalHook = globalStateHook(React, initialState, actions);

export default globalHook;
