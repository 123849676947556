import type { VFC } from "react";
import { Dialog, DialogContent, DialogContentText } from "@material-ui/core";
import type { IconName } from "@fortawesome/free-solid-svg-icons";
import BrowserLink from "./BrowserLink";

const supportedBrowsers: Array<{ name: string; icon: IconName; link: string }> =
  [
    {
      name: "Google Chrome",
      icon: "chrome",
      link: "https://www.google.com/chrome",
    },
    {
      name: "Mozilla Firefox",
      icon: "firefox",
      link: "https://www.mozilla.org/sv-SE/",
    },
    {
      name: "Microsoft Edge",
      icon: "edge",
      link: "https://www.microsoft.com/sv-se/edge#7W2Ue65Gdcs4YLqT.97",
    },
    {
      name: "Apple Safari",
      icon: "safari",
      link: "https://support.apple.com/sv-se/HT204416",
    },
  ];

interface Props {
  show: boolean;
  title: string;
  text: string;
}

const Modal: VFC<Props> = ({ show, title, text }) => (
  <Dialog aria-labelledby="customized-dialog-title" open={show}>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        <h2>{title}</h2>
        {text}
        <ul>
          {supportedBrowsers.map((browser) => (
            <BrowserLink
              name={browser.name}
              icon={browser.icon}
              link={browser.link}
              key={`${browser.name}`}
            />
          ))}
        </ul>
      </DialogContentText>
    </DialogContent>
  </Dialog>
);

export default Modal;
