import { ChangeEvent, useEffect, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import "./UserSettings.css";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import api from "../../utils/api/v1";
import Loader from "../shared/loader/Loader";
import Alert from "../shared/components/Alert";
import Spacer from "../shared/components/Spacer";
import type {
  ApiResponseCompanyBossModel,
  ApiResponseCompanyDepartmentModel,
  ApiResponseCompanyTitleModel,
  ObjectKeys,
} from "../../utils/api/apiInterfaces";
import { FooterModal } from "../footer/FooterModal";

const languages = [
  {
    text: "Introduction.PreferredLanguage.English",
    value: "English",
  },
  {
    text: "Introduction.PreferredLanguage.Swedish",
    value: "Swedish",
  },
];
const genders = [
  {
    text: "Introduction.gender.male",
    value: "male",
  },
  {
    text: "Introduction.gender.female",
    value: "female",
  },
  {
    text: "Introduction.gender.other",
    value: "other",
  },
];
const ageSpans = [
  {
    text: "0-19",
    value: "0",
  },
  {
    text: "20-39",
    value: "20",
  },
  {
    text: "40-59",
    value: "40",
  },
  {
    text: "60-79",
    value: "60",
  },
  {
    text: "80+",
    value: "80",
  },
];

interface User extends ObjectKeys {
  firstName: string;
  lastName: string;
  gender: string;
  ageSpan: string;
  department: string;
  title: string;
  boss: string;
  hasDepartments: boolean;
  hasTitles: boolean;
  hasBosses: boolean;
  preferredLanguage: string;
}

const defaultUser: User = {
  firstName: "",
  lastName: "",
  gender: "",
  ageSpan: "",
  department: "",
  title: "",
  boss: "",
  hasDepartments: false,
  hasTitles: false,
  hasBosses: false,
  preferredLanguage: "",
};

interface State {
  loading: boolean;
  error?: string;
  agreed: boolean;
}

interface Props {
  open: boolean;
  onClose: () => void;
}

// eslint-disable-next-line react/prop-types
const UserSettings: VFC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [state, setState] = useState<State>({
    loading: true,
    error: undefined,
    agreed: false,
  });
  const [user, setUser] = useState<User>(defaultUser);
  const [departments, setDepartments] = useState<
    Array<ApiResponseCompanyDepartmentModel>
  >([]);
  const [titles, setTitles] = useState<Array<ApiResponseCompanyTitleModel>>([]);
  const [bosses, setBosses] = useState<Array<ApiResponseCompanyBossModel>>([]);
  const [form, setForm] = useState<User>(defaultUser);
  const [errors, setErrors] = useState<ObjectKeys>({});
  const [openDataPolicy, setOpenDataPolicy] = useState(false);

  const handleCloseDataPolicy = () => {
    setOpenDataPolicy(false);
  };

  const handleOpenDataPolicy = () => {
    setOpenDataPolicy(true);
  };

  const getUser = async () => {
    setState({ ...state, loading: true, error: undefined });
    try {
      const { data: apiUserData } = await api.getUser();
      const { data: apiDepartmentData } = await api.getDepartments();
      const { data: apiTitleData } = await api.getTitles();
      const { data: apiBossData } = await api.getBosses();
      const userData = {
        firstName: apiUserData.firstName ?? "",
        lastName: apiUserData.lastName ?? "",
        gender: apiUserData.gender ?? "",
        ageSpan: apiUserData.ageSpan ?? "",
        department: apiUserData.departmentId ?? "",
        title: apiUserData.titleId ?? "",
        boss: apiUserData.bossId ?? "",
        hasDepartments: apiUserData.hasDepartments,
        hasTitles: apiUserData.hasTitles,
        hasBosses: apiUserData.hasBosses,
        preferredLanguage: apiUserData.preferredLanguage ?? "",
      };
      setUser(userData);
      setForm(userData);
      setDepartments(apiDepartmentData.departments || []);
      setTitles(apiTitleData.titles || []);
      setBosses(apiBossData.bosses || []);
      setState({ ...state, loading: false });
    } catch (err) {
      setState({
        ...state,
        loading: false,
        error: err instanceof Error ? err.message : "Unknown error",
      });
    }
  };

  useEffect(() => {
    setState({ ...state, agreed: false });
    if (!open) return;
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChange = ({
    target: { name, value },
  }: ChangeEvent<
    HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }
  >) => {
    if (name) {
      setForm({
        ...form,
        [name]: value,
      });
    }
  };

  const handleClose = () => onClose && onClose();

  const handleSave = () => {
    setState({ ...state, loading: true, error: "" });
    const formErrors: ObjectKeys = {};
    const formData = { ...form };
    if (!formData.firstName || !formData.firstName.trim().length) {
      formErrors.firstName = true;
    }
    if (!formData.lastName || !formData.lastName.trim().length) {
      formErrors.lastName = true;
    }
    if (!formData.gender || formData.gender === "") {
      formErrors.gender = true;
    }
    if (!formData.preferredLanguage || formData.preferredLanguage === "") {
      formErrors.preferredLanguage = true;
    }
    if (!formData.ageSpan || Number.isNaN(Number(formData.ageSpan))) {
      formErrors.ageSpan = true;
    }
    if (
      user.hasDepartments &&
      (!formData.department || formData.department === "")
    ) {
      formErrors.department = true;
    }
    if (user.hasTitles && (!formData.title || formData.title === "")) {
      formErrors.title = true;
    }
    if (user.hasBosses && (!formData.boss || formData.boss === "")) {
      formErrors.boss = true;
    }

    if (!state.agreed) {
      formErrors.agreed = true;
    }

    setErrors(formErrors);

    if (Object.keys(formErrors).length) {
      setState({ ...state, loading: false, error: "" });
      return;
    }

    (Object.keys(formData).every((key) => formData[key] === user[key])
      ? Promise.resolve()
      : api.updateUser({
          firstName: formData.firstName.trim(),
          lastName: formData.lastName.trim(),
          gender: formData.gender,
          preferredLanguage: formData.preferredLanguage,
          ageSpan: formData.ageSpan,
          department: formData.department
            ? formData.department.toString()
            : null,
          title: formData.title ? formData.title.toString() : null,
          boss: formData.boss ? formData.boss.toString() : null,
        })
    )
      .then(() => {
        handleClose();
        setState({ ...state, loading: false, error: "" });
      })
      .catch((err) => {
        console.error(err);
        setState({ ...state, loading: false, error: "saving" });
      });
  };

  function formMarkup() {
    if (state.loading) {
      return <Loader text={t("Introduction.loading")} />;
    }
    if (state.error) {
      return (
        <Alert
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "10px",
          }}
        >
          <span style={{ margin: "auto", marginBottom: "12px" }}>
            {state.error}
          </span>
          <Button
            variant="outlined"
            onClick={getUser}
            style={{
              color: "white",
              borderColor: "white",
              margin: "auto",
            }}
          >
            {t("Introduction.tryAgain")}
          </Button>
        </Alert>
      );
    }

    return (
      <FormGroup className="settings-form">
        <FormControl className="settings-form-row">
          <TextField
            id="firstname"
            data-testid="inputFirstname"
            label={t("UserSettings.form.firstName")}
            name="firstName"
            className="introduction-form-row-input"
            value={form.firstName}
            onChange={handleChange}
          />
          {errors.firstName && (
            <span className="introduction-form-error">
              {t("UserSettings.errors.required")}
            </span>
          )}
        </FormControl>

        <FormControl className="settings-form-row">
          <TextField
            id="lastname"
            data-testid="inputLastname"
            label={t("UserSettings.form.lastName")}
            name="lastName"
            className="settings-form-row-input"
            value={form.lastName}
            onChange={handleChange}
          />
          {errors.lastName && (
            <span className="settings-form-error">
              {t("UserSettings.errors.required")}
            </span>
          )}
        </FormControl>

        <FormControl className="settings-form-row">
          <InputLabel id="gender-label">
            {t("UserSettings.form.gender.gender")}
          </InputLabel>
          <Select
            labelId="gender-label"
            id="gender"
            data-testid="inputGender"
            name="gender"
            className="settings-form-row-input"
            value={form.gender}
            onChange={handleChange}
          >
            <MenuItem value="" />
            {genders.map(({ text, value }) => (
              <MenuItem key={value} value={value}>
                {t(text)}
              </MenuItem>
            ))}
          </Select>
          {errors.gender && (
            <span className="settings-form-error">
              {t("UserSettings.errors.required")}
            </span>
          )}
        </FormControl>

        <FormControl className="settings-form-row">
          <InputLabel id="age-label">{t("UserSettings.form.age")}</InputLabel>
          <Select
            labelId="age-label"
            id="age"
            data-testid="inputAge"
            name="ageSpan"
            className="settings-form-row-input"
            value={form.ageSpan}
            onChange={handleChange}
          >
            <MenuItem value="" />
            {ageSpans.map(({ text, value }) => (
              <MenuItem key={value} value={value}>
                {text}
              </MenuItem>
            ))}
          </Select>
          {errors.ageSpan ? (
            <span className="settings-form-error">
              {t("UserSettings.errors.required")}
            </span>
          ) : null}
        </FormControl>

        {user.hasDepartments && (
          <FormControl className="settings-form-row">
            <InputLabel id="department-label">
              {t("UserSettings.form.department")}
            </InputLabel>
            <Select
              labelId="department-label"
              id="department"
              data-testid="inputDepartment"
              name="department"
              className="settings-form-row-input"
              value={form.department}
              onChange={handleChange}
            >
              <MenuItem value="" />
              {departments.map((department) => (
                <MenuItem key={department.id} value={department.id}>
                  {department.name}
                </MenuItem>
              ))}
            </Select>
            {errors.department && (
              <span className="settings-form-error">
                {t("UserSettings.errors.required")}
              </span>
            )}
          </FormControl>
        )}

        {user.hasTitles && (
          <FormControl className="settings-form-row">
            <InputLabel id="title-label">
              {t("UserSettings.form.title")}
            </InputLabel>
            <Select
              labelId="title-label"
              id="title"
              name="title"
              className="settings-form-row-input"
              value={form.title}
              onChange={handleChange}
            >
              <MenuItem value="" />
              {titles.map((title) => (
                <MenuItem key={title.id} value={title.id}>
                  {title.name}
                </MenuItem>
              ))}
            </Select>
            {errors.title && (
              <span className="settings-form-error">
                {t("UserSettings.errors.required")}
              </span>
            )}
          </FormControl>
        )}

        {user.hasBosses && (
          <FormControl className="settings-form-row">
            <InputLabel id="boss-label">
              {t("UserSettings.form.boss")}
            </InputLabel>
            <Select
              labelId="boss-label"
              id="boss"
              name="boss"
              className="settings-form-row-input"
              value={form.boss}
              onChange={handleChange}
            >
              <MenuItem value="" />
              {bosses.map((boss) => (
                <MenuItem key={boss.id} value={boss.id}>
                  {boss.name}
                </MenuItem>
              ))}
            </Select>
            {errors.boss && (
              <span className="settings-form-error">
                {t("UserSettings.errors.required")}
              </span>
            )}
          </FormControl>
        )}
        <FormControl className="settings-form-row">
          <InputLabel id="preferred-language-label">
            {t("Introduction.PreferredLanguage.PreferredLanguageField")}
          </InputLabel>
          <Select
            labelId="preferredLanguage-label"
            id="preferredLanguage"
            name="preferredLanguage"
            className="settings-form-row-input"
            data-testid="inputPreferredLanguage"
            value={form.preferredLanguage}
            onChange={handleChange}
          >
            <MenuItem value="" />
            {languages.map(({ text, value }) => (
              <MenuItem key={value} value={value}>
                {t(text)}
              </MenuItem>
            ))}
          </Select>
          {errors.preferredLanguage && (
            <span className="settings-form-error">
              {t("Introduction.errors.required")}
            </span>
          )}
        </FormControl>
        <FormControl className="settings-form-row">
          <div className="settings-checkbox-consent-text-style">
            <FormControlLabel
              className="settings-form-control-label-style"
              control={
                <Checkbox
                  name="agreed"
                  checked={state.agreed}
                  onChange={(event) =>
                    setState({ ...state, agreed: event.target.checked })
                  }
                  color="primary"
                />
              }
              label=""
            />
            <Typography className="settings-consent-text-style">
              {t("UserSettings.form.dataAgreement")}{" "}
              <span
                className="user-settings-form-row-span-style"
                onClick={handleOpenDataPolicy}
                onKeyPress={handleOpenDataPolicy}
                role="button"
                tabIndex={0}
              >
                <u>{t("UserSettings.form.dataAgreementEnding")}</u>
              </span>
            </Typography>
          </div>
        </FormControl>
        {errors.agreed && (
          <span className="settings-form-error">
            {t("UserSettings.errors.agree")}
          </span>
        )}

        {openDataPolicy && (
          <FooterModal
            open={openDataPolicy}
            handleClose={handleCloseDataPolicy}
            title={t("Introduction.dataPolicyTitle")}
            dataPolicy
          />
        )}

        <div className="buttons">
          <Spacer />
          <Button
            variant="text"
            color="primary"
            className="cancel"
            onClick={handleClose}
          >
            {t("UserSettings.cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="save"
            onClick={handleSave}
          >
            {t("UserSettings.save")}
          </Button>
        </div>
      </FormGroup>
    );
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="settings-modal-title"
      aria-describedby="settings-modal-description"
    >
      <Paper className="settings-wrapper">
        <h2 id="settings-modal-title">{t("UserSettings.title")}</h2>
        <p id="settings-modal-description">{t("UserSettings.description")}</p>
        {formMarkup()}
      </Paper>
    </Modal>
  );
};

export default UserSettings;
