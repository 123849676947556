import React, { useState, VFC } from "react";
import { Button, useMediaQuery, Menu, MenuItem } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import { useNavigate } from "react-router";
import "./TopBar.css";
import { useTranslation } from "react-i18next";
import api from "../../utils/api/v1";
import UserSettings from "../user/UserSettings";
import ChangePassword from "../user/ChangePassword";
import useGlobal from "../../store/store";
import { CalendarInviteModal } from "../user/calendar/CalendarInviteModal";
import { Sources } from "../sources/Sources";
import { AboutUs } from "../topbar-modals/about-us/AboutUs";
import { Integrity } from "../topbar-modals/integrity/Integrity";
import { MenuSeparatorLine } from "../shared/components/SeparatorLine";
import { AboutService } from "../topbar-modals/about-service/AboutService";

const MainMenu: VFC = () => {
  const [, actions] = useGlobal();
  const [settingsModal, setSettingsModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [calendarInviteModalOpen, setCalendarInviteModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const mobile = useMediaQuery("(max-width: 600px)");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const showSettingsModal = () => {
    setSettingsModal(true);
    handleMenuClose();
  };

  const closeSettingsModal = () => {
    setSettingsModal(false);
  };

  const showPasswordModal = () => {
    setPasswordModal(true);
    handleMenuClose();
  };

  const showCalendarInviteModal = () => {
    setCalendarInviteModalOpen(true);
    handleMenuClose();
  };

  const closePasswordModal = () => {
    setPasswordModal(false);
  };

  const handleCloseCalendarModal = () => {
    setCalendarInviteModalOpen(false);
  };

  const logout = async () => {
    try {
      await api.logout();
      actions.auth.signOut();
      navigate("/login");
    } catch (err) {
      console.error(err);
      navigate("/login");
    }
  };

  return (
    <div>
      <Button
        className="top-bar-menu-button"
        id="top-bar-menu-button"
        onClick={handleMenuClick}
        startIcon={<MenuIcon />}
      >
        {!mobile && t("TopBar.menu")}
      </Button>
      <Menu
        id="main-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          id="view-previous-results-menu-item"
          className="main-menu-menu-item-style"
          onClick={() => {
            navigate("/previous-result-page/results");
            handleMenuClose();
          }}
        >
          {t("TopBar.viewPreviousResults")}
        </MenuItem>
        <MenuItem
          id="view-your-team-page"
          className="main-menu-menu-item-style"
          onClick={() => {
            navigate("/your-team-page");
            handleMenuClose();
          }}
        >
          {t("TopBar.yourTeam")}
        </MenuItem>
        <MenuItem
          data-testid="showCalendarInviteModal"
          className="main-menu-menu-item-style"
          onClick={showCalendarInviteModal}
        >
          {t("TopBar.calendarInviteModal")}
        </MenuItem>
        <MenuItem
          className="main-menu-menu-item-style"
          data-testid="showChangePasswordModal"
          onClick={showPasswordModal}
        >
          {t("TopBar.changePassword")}
        </MenuItem>
        <MenuItem
          id="show-settings-modal"
          onClick={showSettingsModal}
          className="main-menu-menu-item-style"
        >
          {t("TopBar.settings")}
        </MenuItem>
        <MenuSeparatorLine />
        <AboutUs />
        <Integrity />
        <Sources />
        <AboutService />
        <MenuSeparatorLine />
        <MenuItem
          data-testid="logoutModal"
          onClick={logout}
          className="main-menu-menu-item-style"
        >
          {t("TopBar.logout")}
        </MenuItem>
      </Menu>
      <UserSettings open={settingsModal} onClose={closeSettingsModal} />
      <ChangePassword open={passwordModal} onClose={closePasswordModal} />
      <CalendarInviteModal
        open={calendarInviteModalOpen}
        handleClose={handleCloseCalendarModal}
        accessedFromMenu
      />
    </div>
  );
};

export default MainMenu;
