import type { VFC } from "react";
import { Link } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { IconName } from "@fortawesome/free-solid-svg-icons";

interface Props {
  name: string;
  icon: IconName;
  link: string;
}

const BrowserLink: VFC<Props> = ({ name, icon, link }) => {
  const { t } = useTranslation();

  return (
    <li style={{ listStyleType: "none", marginBottom: "10px" }}>
      <FontAwesomeIcon icon={["fab", `${icon}`]} size="lg" /> {name} -{" "}
      <Link href={link} variant="body2">
        {t("ButtonGeneral.download")}
      </Link>
    </li>
  );
};

export default BrowserLink;
