import type { FC, CSSProperties } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { defaultColors } from "../../../theme";

const useStyles = makeStyles({
  alert: {
    borderRadius: 2,
    padding: "5px 10px",
    backgroundColor: defaultColors.error,
    border: "none",
    color: "white",
  },
});

interface Props {
  style?: CSSProperties;
}

const Alert: FC<Props> = ({ children, style }) => {
  const classes = useStyles();
  return (
    <div className={classes.alert} style={style}>
      {children}
    </div>
  );
};

export default Alert;
