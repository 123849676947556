import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { refreshTokenIfNeeded } from "./tokenHandler";
import { getToken } from "./sessionhandler";

export class HttpError extends Error {
  public statusCode: number;

  public type: string;

  constructor(message: string, statusCode: number, type: string) {
    super(message);
    this.statusCode = statusCode;
    this.type = type ?? "Unknown error";
  }
}

const handleGetErrorResponse = (err: unknown): HttpError => {
  if (axios.isAxiosError(err)) {
    return new HttpError(
      err?.response?.data?.message ?? "Unknown error",
      err.response?.data?.statusCode ?? 500,
      err.response?.data?.type ?? "Database error"
    );
  }

  return new HttpError("Unknown error", 500, "Database error");
};

export interface Token {
  accessToken: string | null;
  expirationDate: string | null;
  email: string | null;
  refreshToken: string | null;
  expiration: number | null;
}

const compileConfig = (
  config: AxiosRequestConfig,
  accessToken?: string | null
) => ({
  ...config,
  headers: {
    ...(config && config.headers ? config.headers : {}),
    Authorization: `Bearer ${accessToken}`,
  },
});

const get = async <TResult>(
  path: string,
  config: AxiosRequestConfig = {},
  authRequest = true
): Promise<AxiosResponse<TResult>> => {
  try {
    let accessToken = null;
    if (authRequest) {
      accessToken = await refreshTokenIfNeeded(getToken());
    }
    return await axios.get(path, compileConfig(config, accessToken));
  } catch (err) {
    throw handleGetErrorResponse(err);
  }
};

const aDelete = async <TResult>(
  path: string,
  config: AxiosRequestConfig = {},
  authRequest = true
): Promise<AxiosResponse<TResult>> => {
  try {
    let accessToken = null;
    if (authRequest) {
      accessToken = await refreshTokenIfNeeded(getToken());
    }
    return await axios.delete(path, compileConfig(config, accessToken));
  } catch (err) {
    throw handleGetErrorResponse(err);
  }
};

const put = async <TResult, TBody>(
  path: string,
  data: TBody,
  config: AxiosRequestConfig = {},
  authRequest = true
): Promise<AxiosResponse<TResult>> => {
  try {
    let accessToken = null;
    if (authRequest) {
      accessToken = await refreshTokenIfNeeded(getToken());
    }
    return await axios.put(path, data, compileConfig(config, accessToken));
  } catch (err) {
    throw handleGetErrorResponse(err);
  }
};

const post = async <TResult, TBody>(
  path: string,
  data?: TBody,
  config: AxiosRequestConfig = {},
  authRequest = true
): Promise<AxiosResponse<TResult>> => {
  try {
    let accessToken = null;
    if (authRequest) {
      accessToken = await refreshTokenIfNeeded(getToken());
    }
    return await axios.post(path, data, compileConfig(config, accessToken));
  } catch (err) {
    throw handleGetErrorResponse(err);
  }
};

const patch = async <TResult, TBody>(
  path: string,
  data?: TBody,
  config: AxiosRequestConfig = {},
  authRequest = true
): Promise<AxiosResponse<TResult>> => {
  try {
    let accessToken = null;
    if (authRequest) {
      accessToken = await refreshTokenIfNeeded(getToken());
    }
    return await axios.patch(path, data, compileConfig(config, accessToken));
  } catch (err) {
    throw handleGetErrorResponse(err);
  }
};

export default {
  get,
  put,
  post,
  patch,
  delete: aDelete,
};
