import type { VFC } from "react";
import { AppBar, Toolbar, useMediaQuery } from "@material-ui/core";
import { Favorite as Heart, QuestionAnswer } from "@material-ui/icons";
import "./TopBar.css";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../assets/logo.png";
import MainMenu from "./MainMenu";
import LanguagePicker from "./LanguagePicker";
import useGlobal from "../../store/store";

const TopBar: VFC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const mobile = useMediaQuery("(max-width: 850px)");

  const [, actions] = useGlobal();
  const accessTokenExist = actions.auth.accessTokenExist();

  if (location.pathname === "/presentation-page") {
    return null;
  }

  return (
    <AppBar position="static" className="darken-bg">
      <Toolbar className="limit-width">
        <Link to={accessTokenExist ? "/welcome-page" : "/login"}>
          <img
            src={logo}
            alt="dear change."
            data-id="logo"
            className="dc-logo"
            width="143px"
            height="30px"
          />
        </Link>
        <div style={{ flex: 1 }} />
        {!location.pathname.includes("/surveys") && accessTokenExist && (
          <div className="auth-links-container">
            <Link
              to="/counseling-page"
              className={
                mobile
                  ? `my-page-link-style my-page-link-placement`
                  : `my-page-link-style`
              }
              style={
                location.pathname.includes("/counseling-page")
                  ? { color: "var(--color-secondary)" }
                  : { color: "#fff" }
              }
            >
              <QuestionAnswer
                style={mobile ? { marginRight: "" } : { marginRight: "0.5rem" }}
                className="counseling-page-icon-style"
                id="counseling-page-icon-style"
              />
              {!mobile ? t("TopBar.counseling-page") : ""}
            </Link>
            <Link
              to="/library-page"
              className={
                mobile
                  ? `my-page-link-style my-page-link-placement library-icon-style`
                  : `my-page-link-style`
              }
              style={
                location.pathname.includes("/library-page")
                  ? { color: "var(--color-secondary)" }
                  : { color: "#fff" }
              }
            >
              <FontAwesomeIcon
                icon="book"
                className="library-icon-style"
                id="library-page-icon-style"
              />
              {!mobile ? t("TopBar.library-page") : ""}
            </Link>
            <Link
              to="/my-page"
              className={
                mobile
                  ? `my-page-link-style my-page-link-placement`
                  : `my-page-link-style`
              }
              style={
                location.pathname.includes("/my-page")
                  ? { color: "var(--color-secondary)" }
                  : { color: "#fff" }
              }
            >
              <Heart className="my-page-icon-style" id="user-page-icon-style" />
              {!mobile ? t("TopBar.my-page") : ""}
            </Link>
          </div>
        )}

        <LanguagePicker />
        {accessTokenExist && <MainMenu />}
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
