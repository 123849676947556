import type { VFC } from "react";

const SourceArr = [
  "Mindfulness and Psychiatric Medication. Brensilver, M. 2016.",
  "What’s The Difference Between Mindfulness And Meditation? Bushak, L. 2016.",
  "The qualities of natural environments that support the rehabilitation process of individuals with stress-related mental disorder in nature-based rehabilitation.  Pálsdóttir, A.M., Stigsdotter, U.K., Persson, D., Thorpert, P., & Grahn, P. 2018.",
  "Longer nature-based rehabilitation may contribute to a faster return to work in patients with reactions to severe stress and/or depression.  Grahn, P., Pálsdóttir, A.M., Ottosson, J., Jonsdottir, I.H. 2017.",
  "The oxytocinergic system as a mediator of anti-stress and instorative effects induced by nature.  Grahn, P., Ottosson, J., & Uvnäs-Moberg, K. 2021.",
  "Nature-Based Stress Management Course for Individuals at Risk of Adverse Health Effects from Work-Related Stress - Effects on Stress Related Symptoms, Workability and Sick Leave. Sahlin, E., Ahlborg Jr, G., Matuszczyk, J. V. & Grahn, P. 2014.",
  "Recovery through visits in nature : A literature review of nature´s impact on stress. Andreasson N, Larsson E. 2018.",
  "Stress, en skrift om stress och hälsa. Hjärt- och lungfonden, 2018.",
  "Effects of stress on immune function: the good, the bad, and the beautiful. Dhabhar FS, 2014.",
  "Utmattningssyndrom, Institutet för stressmedicin, 2021.",
  "Att leda sig själv, självledarskapsstrategiers betydelse för stress, Mälardalens högskola, 2012.",
  "Skärmhjärnan, Hansen A. 2019.",
  "Förundranseffekten, Sandberg K., Hammarkrantz S. 2020.",
  "The Science of Gratitude.  Greater Good Science Center. 2018.",
  "The Big Book of Creativity Games, Epstein R. 2020.",
  "Embodied Metaphors and Creative “Acts”. Leung A., Kim K., Polman S., Ong E., Qiu L., Goncalo L., Jack A., Sanchez-Burks J. 2011.",
  "Explaining Creativity: The Science of Human Innovation. Sawyer, R.K. 2006.",
  "Creativity: Flow and the psychology of discovery and invention. Csikszentmihalyi M. 1997.",
  "Neurodesign: Inredning för hälsa, prestation och välmående. Sjövall I. 2016.",
  "Hjärnbalans: digital detox i en uppkopplad vardag. Gospic K. 2018.",
  "Give Me a Better Break: Choosing Workday Break Activities to Maximize Resource Recovery. Hunter E. 2015.",
  "Momentary subjective well-being depends on learning and not reward. Blain B., Rutledge. R.B. 2020.",
  "Kindness Counts: Prompting Prosocial Behavior in Preadolescents Boosts Peer Acceptance and Well-Being. Nelson K., Oberle E., Schonert-Reichl K., Lyubomirsky S. 2012.",
  "The role of positive emotions in positive psychology. Fredrickson, B. 2001.",
  "The reciprocal relationship between gratitude and life satisfaction. Unanue W., Esteban Gomez Mella M.,  Cortez D.A., Bravo D., Araya-Véliz C.,  Unanue J., Van Den Broeck A. 2019",
  "The Utrecht Work Engagement Scale: Test manual. Bakker A. Schaufeil W.B. 2017.",
  "Flow: den optimala upplevelsens psykologi. Csíkszentmihályi M. 2021.",
  "Kynurenic Acid and Gpr35 Regulate Adipose Tissue Energy Homeostasis and Inflammation.  Leandro Z. Agudelo, Duarte M. S. Ferreira, Igor Cervenka, Galyna Bryzgalova, Shamim Dadvar, Paulo R. Jannig, Amanda T. Pettersson-Klein, Tadepally Lakshmikanth, Elahu G. Sustarsic, Margareta Porsmyr-Palmertz, Jorge C. Correia, Manizheh Izadi, Vicente Martínez-Redondo, Per M. Ueland, Øivind Midttun, Zachary Gerhart-Hines, Petter Brodin, Teresa Pereira, Per-Olof Berggren, och Jorge L. Ruas. 2018.",
  "Replacing sedentary time with physical activity: a 15-year follow-up of mortality in a national cohort. Dohrn IM , Kwak L , Oja P, Sjöström M, Hagströmer M. 2017.",
  "Why we sleep. Walker M. 2017.",
  "Sleep smarter. Stevenson S. 2020.",
  "Sömnens betydelse för hälsa och arbete. Åkerstedt T. 2001.",
  "Perspectives on the Study of Work-life Balance. Guest D.E. 2002.",
  "Relationships between Job Stress and Worker Perceived Responsibilities and Job Characteristics. Dewa, C., Thompson, A.,  Jacobs, P. 2011.",
  "Balans mellan arbete och privatliv- En viktig fråga för organisation och individ. Carlsson E., Hagelin  S. 2011.",
  "Joy at work: Organizing Your Professional Life. Kondo M., Ishibashi B. 2020.",
  "The Importance of Creating Habits and Routine. Arlinghaus K. R., Johnston C. A. 2018.",
  "Why We Sleep: Unlocking the Power of Sleep and Dreams. Walker H. 2017.",
  "Trötthet och återhämtning. Stressforskningsinstitutet. 2019.",
  "Effects of mindfulness meditation on mindfulness, mental well-being, and perceived stress. Zollars I, Poirier T, Pailden J. 2019.",
  "Self-Compassion, Self-Esteem, and Well-Being. Neff K. D. 2011.",
  "Compassioneffekten. Andersson C. 2017.",
  "The Relationship Between Self-Compassion and Well-Being: A Meta-Analysis. Zessin U, Dickhäuser O, Garbade S. 2015.",
  "Enhancing Grit: Possibility and Intervention Strategies. Hwang M. H. 2021.",
  "GRIT. Duckworth A. 2016.",
  "Lev med din kropp- om acceptans och självkänsla. Gaderi A., Parling T. 2009.",
  "Exploring the relationship between appearance-contingent self-worth and self-esteem. Adams, K. E., Tyler, J. M., Calogero, R., & Lee, J 2017.",
  "Keep your brain alive. Katz L., Rubin M. 2012.",
  "Motivation inom träning, hälsa och idrott. Lindwall M., Stenling A., Weman Josefsson K., Edler K., Gustafsson H.. 2019.",
  "Living with Your Body and Other Things You Hate.  Sandoz E. K., DuFrene T. 2014.",
  "The Joy Of Movement. McGonigal K. 2019.",
];

export const SourceList: VFC = () => (
  <div>
    <ul>
      {SourceArr.map((source) => (
        <li>{source}</li>
      ))}
    </ul>
  </div>
);
