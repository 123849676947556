import { BrowserRouter, Route } from "react-router-dom";
import { Routes } from "react-router";
import { lazy, VFC } from "react";
import { lazily } from "react-lazily";
import { Footer } from "../components/footer/Footer";
import TopBar from "../components/topbar/TopBar";
import { MicroCoursePage } from "../components/micro-course-page/MicroCoursePage";
import { MicroCourseResourcePage } from "../components/micro-course-page/micro-course-resource-page/MicroCourseResourcePage";
import { PreviousResultPage } from "../components/previous-result-page/PreviousResultPage";
import { YourTeamPage } from "../components/your-team/YourTeamPage";

const AuthRoute = lazy(() => import("../components/AuthRoute"));
const NonAuthRoute = lazy(() => import("../components/NonAuthRoute"));
const SignInPage = lazy(() => import("../components/signin/SignInPage"));
const WelcomePage = lazy(() => import("../components/welcome/WelcomePage"));
const IntroductionPage = lazy(
  () => import("../components/introduction/IntroductionPage")
);
const LibraryPage = lazy(
  () => import("../components/library-page/LibraryPage")
);
const ResourcePage = lazy(
  () => import("../components/resource-page/ResourcePage")
);
const SurveyPage = lazy(() => import("../components/survey/SurveyPage"));
const UserPage = lazy(() => import("../components/user/UserPage"));
const SurveyResult = lazy(
  () => import("../components/survey/surveyresult/SurveyResult")
);
const ResetPasswordPage = lazy(
  () => import("../components/resetpassword/ResetPasswordPage")
);
const ResetPasswordWithTokenPage = lazy(
  () => import("../components/resetpassword/ResetPasswordWithTokenPage")
);
const PresentationPage = lazy(
  () => import("../components/presentation-page/PresentationPage")
);
const { ActivityPage } = lazily(
  () => import("../components/activity-page/ActivityPage")
);
const { CounselingPage } = lazily(
  () => import("../components/counseling-page/CounselingPage")
);
const { CounselingResourcePage } = lazily(
  () => import("../components/counseling-resource-page/CounselingResourcePage")
);

const AppRoutes: VFC = () => (
  <BrowserRouter>
    <TopBar />
    <div className="container limit-width">
      <Routes>
        <Route
          path="/login"
          element={
            <NonAuthRoute>
              <SignInPage />
            </NonAuthRoute>
          }
        />
        <Route
          path="/reset-password"
          element={
            <NonAuthRoute>
              <ResetPasswordPage />
            </NonAuthRoute>
          }
        />
        <Route
          path="/reset-password/:token"
          element={
            <NonAuthRoute>
              <ResetPasswordWithTokenPage />
            </NonAuthRoute>
          }
        />
        <Route
          path="/intro"
          element={
            <AuthRoute>
              <IntroductionPage />
            </AuthRoute>
          }
        />
        <Route
          path="/my-page"
          element={
            <AuthRoute>
              <UserPage />
            </AuthRoute>
          }
        />
        <Route
          path="/surveys/:surveyId/:cycleId"
          element={
            <AuthRoute>
              <SurveyPage />
            </AuthRoute>
          }
        />
        <Route
          path="/surveys/result/:surveyId/:cycleId"
          element={
            <AuthRoute>
              <SurveyResult />
            </AuthRoute>
          }
        />
        <Route
          path="/surveys/result/:surveyId/:cycleId/from-overview"
          element={
            <AuthRoute>
              <SurveyResult overview />
            </AuthRoute>
          }
        />
        <Route
          path="/previous-result-page/results"
          element={
            <AuthRoute>
              <PreviousResultPage />
            </AuthRoute>
          }
        />
        <Route
          path="/presentation-page"
          element={
            <AuthRoute>
              <PresentationPage />
            </AuthRoute>
          }
        />
        <Route
          path="/your-team-page"
          element={
            <AuthRoute>
              <YourTeamPage />
            </AuthRoute>
          }
        />
        <Route
          path="/library-page"
          element={
            <AuthRoute>
              <LibraryPage />
            </AuthRoute>
          }
        />
        <Route
          path="/counseling-page"
          element={
            <AuthRoute>
              <CounselingPage />
            </AuthRoute>
          }
        />
        <Route
          path="/counseling-resource-page/:counselingResourceId"
          element={
            <AuthRoute>
              <CounselingResourcePage />
            </AuthRoute>
          }
        />
        <Route
          path="/resource-page/:resourceId"
          element={
            <AuthRoute>
              <ResourcePage />
            </AuthRoute>
          }
        />
        <Route
          path="/micro-course-page/:microCourseId"
          element={
            <AuthRoute>
              <MicroCoursePage />
            </AuthRoute>
          }
        />
        <Route
          path="/micro-course/micro-course-resource/:microCourseResourceId"
          element={
            <AuthRoute>
              <MicroCourseResourcePage />
            </AuthRoute>
          }
        />
        <Route
          path="/my-page/:moduleOrder/:activityId"
          element={
            <AuthRoute>
              <ActivityPage />
            </AuthRoute>
          }
        />
        <Route
          path="*"
          element={
            <AuthRoute>
              <WelcomePage />
            </AuthRoute>
          }
        />
      </Routes>
    </div>
    <Footer />
  </BrowserRouter>
);

export default AppRoutes;
