import type { CSSProperties, VFC } from "react";
import { CircularProgress } from "@material-ui/core";
import "./Loader.css";

interface Props {
  style?: CSSProperties;
  text?: string;
  size: number;
}

export const SmallLoader: VFC<Props> = ({ text, size, style }) => (
  <div className="small-loading-wrapper" {...{ style }}>
    <CircularProgress
      className="loading-indicator"
      variant="indeterminate"
      size={size}
    />
    {text && <h6 className="small-loading-text">{text}</h6>}
  </div>
);
