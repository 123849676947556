import { useCallback, useEffect, useState, VFC } from "react";
import { Button, Paper, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import api from "../../utils/api/v1";
import "./PreviousResultPage.css";
import { PreviousResultPageLoadingSkeleton } from "./PreviousResultPageLoadingSkeleton";
import { PreviousResultPageSurveyResultCard } from "./PreviousResultPageSurveyResultCard";
import { ErrorPanelWithReload } from "../shared/components/error-panels/error-panel-with-reload/ErrorPanelWithReload";
import { NextTermGoal } from "./NextTermGoal";
import { SeparatorLine } from "../shared/components/SeparatorLine";
import { TermCategoryComparisonRadialChartWrapper } from "./TermCategoryComparisonRadialChartWrapper";
import type { ApiResponsePreviousUserResultsModel } from "../../utils/api/apiInterfaces";

interface State {
  loading: boolean;
  error?: string;
}

export const PreviousResultPage: VFC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState<State>({
    loading: false,
    error: undefined,
  });
  const [data, setData] = useState<ApiResponsePreviousUserResultsModel>({
    previousUserResults: [],
  });
  const getUserPreviousResults = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
      error: undefined,
    }));
    try {
      const {
        data: { previousUserResults },
      } = await api.getUserPreviousResults();
      setState((prevState) => ({
        ...prevState,
        loading: false,
        error: undefined,
      }));
      setData((prevState) => ({ ...prevState, previousUserResults }));
    } catch (err) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        error: err instanceof Error ? err.message : "Unknown error",
      }));
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getUserPreviousResults();
    })();
  }, [getUserPreviousResults]);

  return (
    <div className="previous-result-page-wrapper">
      <Paper elevation={10} className="previous-result-page-paper-container">
        {state.error ? (
          <ErrorPanelWithReload
            apiReloadCall={getUserPreviousResults}
            message={state.error}
          />
        ) : (
          <>
            <Typography variant="h3" className="previous-result-page-header">
              {t("PreviousResultPage.mainHeader")}
            </Typography>
            <p className="previous-result-page-paragraph-text">
              {t("PreviousResultPage.informationText")}
            </p>
            {state.loading && <PreviousResultPageLoadingSkeleton />}
            {!state.loading && (
              <>
                {data.previousUserResults.length < 1 && (
                  <h2 className="previous-result-page-no-finished-surveys">
                    {t("PreviousResultPage.noResults")}
                  </h2>
                )}
                {data.previousUserResults.map((pur) => (
                  <div className="previous-result-card-goal-container">
                    <PreviousResultPageSurveyResultCard
                      key={`${pur.userCycleResultFinishedAt}-${pur.unencryptedAverageResult}`}
                      previousResult={pur}
                    />
                    <NextTermGoal
                      nextTermGoal={pur.nextTermGoal}
                      nextTermNumber={pur.termId + 1}
                      surveyId={pur.surveyId}
                      cycleId={pur.cycleId}
                    />
                    <SeparatorLine className="previous-result-page-separator-line" />
                  </div>
                ))}
              </>
            )}
          </>
        )}
        {data.previousUserResults.length > 1 && (
          <TermCategoryComparisonRadialChartWrapper
            previousUserResults={data.previousUserResults}
          />
        )}
        <div className="previous-result-page-close-button">
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/library-page")}
          >
            {t("PreviousResultPage.closePage")}
          </Button>
        </div>
      </Paper>
    </div>
  );
};
