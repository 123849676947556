import React, { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import Slide from "@material-ui/core/Slide";
import PropTypes from "prop-types";
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import type { TransitionProps } from "@material-ui/core/transitions/transition";
import logo from "../../../assets/logo.png";
import "../../footer/Footer.css";
import "./Integrity.css";

interface Props {
  open: boolean;
  handleClose: () => void;
  title: string;
  generalTerms?: boolean;
  dataPolicy?: boolean;
}

export const IntegrityModal: VFC<Props> = ({
  open,
  handleClose,
  title,
  generalTerms,
  dataPolicy,
}) => {
  const { t } = useTranslation();

  const Transition = React.forwardRef(
    (
      props: React.PropsWithChildren<
        TransitionProps & {
          /* eslint-disable  @typescript-eslint/no-explicit-any */
          children?: React.ReactElement<any, any> | undefined;
        }
      >,
      ref
    ) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Slide direction="left" ref={ref} {...props} />
    )
  );

  const [openDataPolicy, setOpenDataPolicy] = useState(false);

  const handleOpenDataPolicy = () => {
    setOpenDataPolicy(true);
  };

  const handleCloseDataPolicy = () => {
    setOpenDataPolicy(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="source-dialog-wrapper"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography className="alert-dialog-title-style">
          {title}{" "}
          <img
            src={logo}
            alt="dear change."
            data-id="logo"
            className="dc-logo-modal-popup"
          />
        </Typography>
      </DialogTitle>
      <DialogContent>
        {generalTerms && (
          <DialogContentText className="alert-dialog-content-style">
            {t("Introduction.generalTermsIntro")} <br />
            <br />
            <b>{t("Introduction.generalTermsBody1Title")}</b>
            <br />
            {t("Introduction.generalTermsBody1")}
            <span
              className="modal-row-span-style-text"
              onClick={handleOpenDataPolicy}
              onKeyPress={handleOpenDataPolicy}
              role="button"
              tabIndex={0}
            >
              <b>
                <u>{t("Introduction.dataProtectionPolicy")}</u>
              </b>
            </span>
            <br />
            <br />
            <b>{t("Introduction.generalTermsBody2Title")}</b>
            <br />
            {t("Introduction.generalTermsBody2-1")}
            <br />
            <br />
            {t("Introduction.generalTermsBody2-2")}
            <br />
            <br />
            {t("Introduction.generalTermsBody2-3")}
            <br />
            <br />
            <b>{t("Introduction.generalTermsBody3Title")}</b>
            <br />
            {t("Introduction.generalTermsBody3-1")}
            <br />
            <br />
            {t("Introduction.generalTermsBody3-2")}
            <br />
            <br />
            <b>{t("Introduction.generalTermsBody4Title")}</b>
            <br />
            {t("Introduction.generalTermsBody4")}
            <br />
            <br />
            <b>{t("Introduction.generalTermsBody5Title")}</b>
            <br />
            {t("Introduction.generalTermsBody5")}
            <br />
            <br />
            <b>{t("Introduction.generalTermsBody6Title")}</b>
            <br />
            {t("Introduction.generalTermsBody6")}
          </DialogContentText>
        )}

        {dataPolicy && (
          <DialogContentText className="alert-dialog-content-style">
            <b>{t("Introduction.dataPolicyBody1Title")}</b> <br />
            <i>{t("Introduction.dataPolicyBody1-1")}</i> <br />
            <br />
            <i>{t("Introduction.dataPolicyBody1-2")}</i> <br />
            <br />
            <i>{t("Introduction.dataPolicyBody1-3")}</i> <br />
            <br />
            <b>{t("Introduction.dataPolicyBody2Title")}</b> <br />
            {t("Introduction.dataPolicyBody2")} <br />
            <br />
            <b>{t("Introduction.dataPolicyBody3Title")}</b> <br />
            {t("Introduction.dataPolicyBody3")} <br />
            <br />
            <b>{t("Introduction.dataPolicyBody4Title")}</b> <br />
            {t("Introduction.dataPolicyBody4-1")} <br />
            <br />
            {t("Introduction.dataPolicyBody4-2")} <br />
            <br />
            <b>{t("Introduction.dataPolicyBody5Title")}</b> <br />
            {t("Introduction.dataPolicyBody5")} <br />
            <br />
            <b>{t("Introduction.dataPolicyBody6Title")}</b> <br />
            {t("Introduction.dataPolicyBody6-1")} <br />
            <br />
            {t("Introduction.dataPolicyBody6-2")} <br />
            <br />
            {t("Introduction.dataPolicyBody6-3")} <br />
            <br />
            {t("Introduction.dataPolicyBody6-4")} <br />
            <br />
            {t("Introduction.dataPolicyBody6-5")} <br />
            <br />
            <b>{t("Introduction.dataPolicyBody7Title")}</b> <br />
            {t("Introduction.dataPolicyBody7")} <br />
            <br />
            <b>{t("Introduction.dataPolicyBody8Title")}</b> <br />
            {t("Introduction.dataPolicyBody8")} <br />
            <br />
            <b>{t("Introduction.dataPolicyBody9Title")}</b> <br />
            {t("Introduction.dataPolicyBody9-1")} <br />
            <br />
            {t("Introduction.dataPolicyBody9-2")} <br />
            <br />
            <b>{t("Introduction.dataPolicyBody10Title")}</b> <br />
            {t("Introduction.dataPolicyBody10")} <br />
            <br />
            <b>{t("Introduction.dataPolicyBody11Title")}</b> <br />
            {t("Introduction.dataPolicyBody11")} <br />
            <br />
            <b>{t("Introduction.dataPolicyBody12Title")}</b> <br />
            {t("Introduction.dataPolicyBody12")} <br />
            <br />
            <b>{t("Introduction.dataPolicyBody13Title")}</b> <br />
            {t("Introduction.dataPolicyBody13")} <br />
            <br />
            <b>{t("Introduction.dataPolicyBody14Title")}</b> <br />
            {t("Introduction.dataPolicyBody14")} <br />
            <br />
            <b>{t("Introduction.dataPolicyBody15Title")}</b> <br />
            {t("Introduction.dataPolicyBody15")} <br />
            <br />
            <b>{t("Introduction.dataPolicyBody16Title")}</b> <br />
            <i>{t("Introduction.dataPolicyBody16")}</i> <br />
            <br />
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          id="footer-modal-close"
          onClick={handleClose}
          color="primary"
          autoFocus
        >
          {t("Footer.modalClose")}
        </Button>
        {openDataPolicy && (
          <IntegrityModal
            open={openDataPolicy}
            handleClose={handleCloseDataPolicy}
            title={t("Introduction.dataPolicyTitle")}
            dataPolicy
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

IntegrityModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  generalTerms: PropTypes.bool,
  dataPolicy: PropTypes.bool,
};

IntegrityModal.defaultProps = {
  generalTerms: false,
  dataPolicy: false,
};
