import type { ApiResponseAccessTokenResource } from "./api/apiInterfaces";

const TOKEN_KEY = "token";

export function getToken() {
  const token: string | null = window.localStorage.getItem(TOKEN_KEY);
  if (token) {
    return JSON.parse(token);
  }

  return null;
}

export function setToken(token: ApiResponseAccessTokenResource) {
  return window.localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
}

export function clearToken() {
  return window.localStorage.removeItem(TOKEN_KEY);
}
