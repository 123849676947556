import { VFC, useState, useEffect } from "react";
import "./CircleDiagram.css";
import { useMatch } from "react-router";

interface Props {
  text?: string;
  size?: number;
  value?: number;
  color?: string;
  textColor?: string;
  moduleState?: string;
  className?: string;
}

const CircleDiagram: VFC<Props> = ({
  value = 0,
  text,
  color = "#068466",
  size = 64,
  textColor = "#999",
  moduleState = "noPropsPassed",
}) => {
  const [loading, setLoading] = useState(true);
  const routeMatch = useMatch("/my-page");
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  return (
    <div
      className="circle-diagram"
      data-testid="circle-label_number_of_program"
      style={{ width: size, height: size }}
    >
      <svg
        className="circle-svg"
        viewBox="22 22 44 44"
        style={
          value === 0 && !loading && !routeMatch
            ? { border: "2px solid red", borderRadius: "50%" }
            : { border: "", borderRadius: "" }
        }
      >
        <circle
          className="circle-bg"
          cx="44"
          cy="44"
          r="22"
          fill="#ddd"
          stroke="none"
        />
        <circle
          className="circle"
          data-testid="statistic_color_of_module"
          strokeDasharray={101}
          strokeDashoffset={`${
            101 - (Math.min(10, Math.max(value, 0)) / 10) * 101
          }px`}
          cx="44"
          cy="44"
          r="16"
          fill="none"
          strokeWidth="12.1"
          stroke={color}
        />
        <circle
          className="circle-label-bg"
          cx="44"
          cy="44"
          r="16"
          fill="white"
          stroke="none"
        />
        <text
          textAnchor="middle"
          x="100%"
          y="47.5"
          className="circle-label"
          stroke="none"
          style={
            moduleState === "open"
              ? { fill: `${textColor}`, fontWeight: "bold" }
              : { fill: `${textColor}`, fontWeight: "" }
          }
        >
          {text}
        </text>
      </svg>
    </div>
  );
};

export default CircleDiagram;
