import moment from "moment";

const getNextDay = (day, date = new Date()) => {
  const dateCopy = new Date(date.getTime());

  return new Date(
    dateCopy.setDate(
      dateCopy.getDate() + ((7 - dateCopy.getDay() + day) % 7 || 7)
    )
  );
};

export const addZeroToValue = (value) =>
  value.length === 1 ? `0${value}` : value;

export const generateUntilTime = () => {
  const initialUntilDate = moment(new Date()).add(6, "M").toDate();

  const calendarDateUntilYear = initialUntilDate.getFullYear();
  const calendarDateUntilMonth = initialUntilDate.getMonth();
  const calendarDateUntilDate = initialUntilDate.getDate();

  return `RRULE:FREQ=WEEKLY;UNTIL=${calendarDateUntilYear}${addZeroToValue(
    `${calendarDateUntilMonth + 1}`
  )}${addZeroToValue(`${calendarDateUntilDate}`)}T000000Z;`;
};

const getDecimalPartOfNumberInMinutes = (num) => {
  if (Number.isInteger(num)) return 0;

  const decimal = Number(`.${num.toString().split(".")[1]}`);
  return (60 * decimal).toFixed(0);
};

const generateOffsetValueStringFromHours = (valueInHours) => {
  if (valueInHours < 1) {
    const minutes = (valueInHours * 60).toFixed(2);
    return `00:${minutes}`;
  }

  const wholeHours = Math.trunc(valueInHours);
  const wholeMinutes = getDecimalPartOfNumberInMinutes(valueInHours);
  const hours = wholeHours < 10 ? `0${wholeHours}` : String(wholeHours);
  const minutes = wholeMinutes < 10 ? `0${wholeMinutes}` : String(wholeMinutes);

  return `${hours}:${minutes}`;
};

const getTimeZoneAdjustment = (tzo) => {
  const stringTo = String(tzo);
  const sign = stringTo.substring(0, 1);
  const timeOffset = Number(stringTo.substring(1));
  const valueInHours = timeOffset / 60;
  const time = generateOffsetValueStringFromHours(valueInHours);
  const reversedSign = sign === "-" ? "+" : "-";

  return `${reversedSign}${time}`;
};

export const generateStartAndEndTime = (day, date = new Date()) => {
  // Get time zone offset.
  const tzo = date.getTimezoneOffset();
  const timeAdjustment = getTimeZoneAdjustment(tzo);

  // Setting meeting start time.
  const nextDayDate = getNextDay(day, date);
  const fullYear = nextDayDate.getFullYear();
  const month = nextDayDate.getMonth();
  const nextDate = nextDayDate.getDate();
  const hours = nextDayDate.getHours();
  const minutes = nextDayDate.getMinutes();
  const startTime = `${fullYear}-${addZeroToValue(
    `${month + 1}`
  )}-${addZeroToValue(`${nextDate}`)}T${addZeroToValue(
    `${hours}`
  )}:${addZeroToValue(`${minutes}`)}${timeAdjustment}`;

  // Setting meeting end time.
  const newNextDayDate = moment(nextDayDate).add(30, "m").toDate();
  const newYear = newNextDayDate.getFullYear();
  const newMonth = newNextDayDate.getMonth();
  const newDate = newNextDayDate.getDate();
  const newHours = newNextDayDate.getHours();
  const newMinutes = newNextDayDate.getMinutes();
  const endTime = `${newYear}-${addZeroToValue(
    `${newMonth + 1}`
  )}-${addZeroToValue(`${newDate}`)}T${addZeroToValue(
    `${newHours}`
  )}:${addZeroToValue(`${newMinutes}`)}${timeAdjustment}`;

  const untilTime = generateUntilTime();

  return {
    startTime,
    endTime,
    untilTime,
  };
};
