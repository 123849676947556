import type { VFC } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import "./YourTeamPage.css";

export const YourTeamPageLoadingSkeleton: VFC = () => (
  <Skeleton
    variant="rect"
    width="100%"
    className="your-team-page-loading-skeleton"
  />
);
