import Carousel from "react-elastic-carousel";
import { useMemo, VFC } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { MicroCourseResourcePdf } from "./MicroCourseResourcePdf";
import { getLanguage } from "../../i18n";
import type { ApiResponseMicroCourseResourceWithOrder } from "./MicroCoursePage";

interface Props {
  microCourseResources: Array<ApiResponseMicroCourseResourceWithOrder>;
}

export const MicroCourseResourcePdfCarousel: VFC<Props> = ({
  microCourseResources,
}) => {
  const lang = getLanguage();
  const matches = useMediaQuery("(max-width:428px)");
  const breakPoints = useMemo(
    () => [
      { width: 149, itemsToShow: 1, itemsToScroll: 1, pagination: false },
      {
        width: !matches ? 245 : 350,
        itemsToShow: 2,
        itemsToScroll: 1,
        pagination: false,
      },
      {
        width: 750,
        itemsToShow: 3,
        itemsToScroll: 1,
        pagination: false,
      },
      {
        width: 950,
        itemsToShow: 4,
        itemsToScroll: 1,
        pagination: false,
      },
    ],
    [matches]
  );
  return (
    <Carousel
      breakPoints={breakPoints}
      isRTL={false}
      enableSwipe
      enableMouseSwipe={false}
      disableArrowsOnEnd
    >
      {microCourseResources.map((mcr) => (
        <MicroCourseResourcePdf
          key={mcr.id}
          name={lang === "sv" ? mcr.nameSv ?? "" : mcr.nameEn ?? ""}
          downloadUrl={
            lang === "sv"
              ? mcr.resourcePdfUrl ?? ""
              : mcr.resourcePdfUrlEn ?? ""
          }
        />
      ))}
    </Carousel>
  );
};
