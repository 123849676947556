import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import Slide from "@material-ui/core/Slide";
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import type { TransitionProps } from "@material-ui/core/transitions/transition";
import logo from "../../../assets/logo.png";
import "../../footer/Footer.css";

interface Props {
  open: boolean;
  handleClose: () => void;
  title: string;
  aboutUs?: boolean;
}

export const AboutUsModal: VFC<Props> = ({
  open,
  handleClose,
  title,
  aboutUs = false,
}) => {
  const { t } = useTranslation();
  const Transition = React.forwardRef(
    (
      props: React.PropsWithChildren<
        TransitionProps & {
          /* eslint-disable  @typescript-eslint/no-explicit-any */
          children?: React.ReactElement<any, any> | undefined;
        }
      >,
      ref
    ) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Slide direction="left" ref={ref} {...props} />
    )
  );
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="source-dialog-wrapper"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography className="alert-dialog-title-style">
          {title}{" "}
          <img
            src={logo}
            alt="dear change."
            data-id="logo"
            className="dc-logo-modal-popup"
          />
        </Typography>
      </DialogTitle>
      <DialogContent>
        {aboutUs && (
          <DialogContentText
            id="alert-dialog-content-style"
            className="alert-dialog-content-style"
          >
            <b>Dear Change</b> {t("Footer.aboutContent.p1")} <br /> <br />
            <b>Dear Change</b> {t("Footer.aboutContent.p2")} <br /> <br />
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          id="footer-modal-close"
          onClick={handleClose}
          color="primary"
          autoFocus
        >
          {t("Footer.modalClose")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
