import { Button, Paper, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import type { VFC } from "react";
import "./ErrorPanelWithReload.css";
import Alert from "../../Alert";

interface Props {
  message: string;
  apiReloadCall: () => Promise<void>;
}

export const ErrorPanelWithReload: VFC<Props> = ({
  apiReloadCall,
  message,
}) => {
  const { t } = useTranslation();
  return (
    <Paper className="error-panel-with-reload-wrapper">
      <Alert>
        <Typography
          variant="body1"
          className="error-panel-with-reload-error-message-text"
        >
          {message}
        </Typography>
        <Button variant="contained" color="secondary" onClick={apiReloadCall}>
          {t("ButtonGeneral.tryAgain")}
        </Button>
      </Alert>
    </Paper>
  );
};
