import { ChangeEvent, useEffect, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import "./MicroCoursePage.css";
import { Button, CardMedia, Paper, Typography } from "@material-ui/core";
import { useNavigate, useParams } from "react-router";
import { Rating } from "@material-ui/lab";
import { Share } from "@material-ui/icons";
import api from "../../utils/api/v1";
import { getLanguage } from "../../i18n";
import imagePlaceholder from "../../assets/imagePlaceholder.jpg";
import { ErrorPanelWithReload } from "../shared/components/error-panels/error-panel-with-reload/ErrorPanelWithReload";
import { AlertBox } from "../shared/components/AlertBox";
import { SmallLoader } from "../shared/loader/SmallLoader";
import { MicroCoursePageSkeleton } from "./MicroCoursePageSkeleton";
import { MicrcCourseResourceCard } from "./MicroCourseResourceCard";
import { MicroCourseResourcePdf } from "./MicroCourseResourcePdf";
import { MicroCourseResourceCarousel } from "./MicroCourseResourceCarousel";
import { MicroCourseResourcePdfCarousel } from "./MicroCourseResourcePdfCarousel";
import type {
  ApiResponseMicroCourse,
  ApiResponseMicroCourseResource,
  ObjectKeys,
} from "../../utils/api/apiInterfaces";
import { MissingRequiredParamError } from "../shared/components/MissingRequiredParamError";

const convertFirstLetterToUppercase = (text: string) =>
  text.charAt(0).toUpperCase() + getLanguage().slice(1);

interface State {
  loading: boolean;
  error?: string;
  ratingError?: string;
  ratingLoading: boolean;
  microCourse: ApiResponseMicroCourse | null;
  microCourseResources: Array<ApiResponseMicroCourseResourceWithOrder>;
}

export interface ApiResponseMicroCourseResourceWithOrder
  extends ApiResponseMicroCourseResource,
    ObjectKeys {
  order: number;
}

export const MicroCoursePage: VFC = () => {
  const { t } = useTranslation();
  const { microCourseId } = useParams();
  const [state, setState] = useState<State>({
    loading: false,
    error: undefined,
    ratingError: undefined,
    ratingLoading: false,
    microCourse: null,
    microCourseResources: [],
  });
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await getMicroCourse();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!microCourseId) {
    return <MissingRequiredParamError missingParam="Missing microCourseId" />;
  }

  const getMicroCourse = async () => {
    setState({
      ...state,
      loading: true,
      error: undefined,
      ratingError: undefined,
    });
    try {
      const {
        data: { microCourse, microCourseResources },
      } = await api.getMicroCourse(microCourseId);

      const microCourseResourcesWithUpdatedOrder = microCourseResources
        .sort((a, b) => a.order - b.order)
        .map((mcr, i) => ({
          ...mcr,
          order: i + 1,
        }));

      setState({
        ...state,
        microCourse,
        microCourseResources: microCourseResourcesWithUpdatedOrder,
        loading: false,
      });
    } catch (err) {
      if (err instanceof Error) {
        setState({ ...state, loading: false, error: err.message });
      } else {
        setState({ ...state, loading: false, error: "Unknown" });
      }
    }
  };

  const getEmailBody = (microCourse: ApiResponseMicroCourse) => {
    if (getLanguage() === "en") {
      return `Hi, %0d%0a%0d%0aI saw this content on the Dear Change Portal and I thought you might be interested in it as well. %0d%0a${
        microCourse[`name${convertFirstLetterToUppercase(getLanguage())}`]
      }: ${process.env.REACT_APP_URL}/micro-course-page/${microCourse.id}`;
    }
    return `Hej, %0d%0a%0d%0aJag upptäckte denna resurs på Dear Change Portal och tänkte att du också skulle kunna vara intresserad. %0d%0a${
      microCourse[`name${convertFirstLetterToUppercase(getLanguage())}`]
    }: ${process.env.REACT_APP_URL}/micro-course-page/${microCourse.id}`;
  };

  const rateMicroCourse = async (
    e: ChangeEvent<{ value: string }>,
    microCourse: ApiResponseMicroCourse
  ) => {
    setState({ ...state, ratingLoading: true, ratingError: undefined });
    const { value } = e.target;
    const data = {
      rating: parseFloat(value),
    };

    try {
      await api.rateMicroCourse(microCourse.id, data);
      setState({ ...state, ratingLoading: false });
    } catch (err) {
      if (err instanceof Error) {
        setState({ ...state, ratingLoading: false, ratingError: err.message });
      } else {
        setState({
          ...state,
          ratingLoading: false,
          ratingError: "Unknown error",
        });
      }
    }
  };

  const lang = getLanguage();

  const microCoursesWithPdfs = state.microCourseResources.filter((mcr) =>
    lang === "sv" ? Boolean(mcr.resourcePdfUrl) : Boolean(mcr.resourcePdfUrlEn)
  );

  return (
    <div className="micro-course-page-wrapper">
      {state.error ? (
        <ErrorPanelWithReload
          message={state.error}
          apiReloadCall={getMicroCourse}
        />
      ) : state.loading || state.microCourse === null ? (
        <MicroCoursePageSkeleton />
      ) : (
        <Paper elevation={10} className="micro-course-page-paper-container">
          <div>
            <Typography variant="h3" className="micro-course-page-header">
              {
                state.microCourse.textData[
                  `name${lang.charAt(0).toUpperCase() + lang.slice(1)}`
                ]
              }
            </Typography>
            {!state.microCourse.imageUrl ? (
              <CardMedia
                image={imagePlaceholder}
                className="micro-course-card-media-style"
              />
            ) : (
              <CardMedia
                image={`${
                  state.microCourse.imageUrl.split("/image/upload/")[0]
                }/image/upload/w_1000,c_limit/${
                  state.microCourse.imageUrl.split("/image/upload/")[1]
                }`}
                className="micro-course-card-media-style"
              />
            )}
          </div>
          <div>
            <p className="micro-course-page-text-description">
              {
                state.microCourse.textData[
                  `description${lang.charAt(0).toUpperCase() + lang.slice(1)}`
                ]
              }
            </p>
            <div className="micro-course-bottom-content-wrapper">
              <h4 className="micro-course-all-resources-header">
                {t("MicroCoursePage.allResourcesHeader")}
              </h4>
              {state.microCourseResources.length <= 3 ? (
                <div className="micro-course-images-wrapper">
                  {state.microCourseResources.map((mcr) => (
                    <MicrcCourseResourceCard
                      microCourseResourceWithOrder={mcr}
                    />
                  ))}
                </div>
              ) : (
                <MicroCourseResourceCarousel
                  microCourseResources={state.microCourseResources}
                />
              )}

              {microCoursesWithPdfs.length <= 3 ? (
                <div className="micro-course-pdf-wrapper">
                  {microCoursesWithPdfs.map((mcr) => (
                    <MicroCourseResourcePdf
                      key={mcr.id}
                      name={`${t("MicroCoursePage.part")} ${mcr.order}`}
                      downloadUrl={
                        lang === "sv"
                          ? mcr.resourcePdfUrl ?? ""
                          : mcr.resourcePdfUrlEn ?? ""
                      }
                    />
                  ))}
                </div>
              ) : (
                <MicroCourseResourcePdfCarousel
                  microCourseResources={microCoursesWithPdfs}
                />
              )}

              <div className="micro-course-page-button-container">
                <Button
                  onClick={() => navigate("/library-page")}
                  color="primary"
                  variant="contained"
                  id="micro-course-navigate-back-to-library"
                  data-testid="navigateBackToLibraryBtn"
                >
                  {t("MicroCoursePage.backToLibrary")}
                </Button>
                <div className="micro-course-resource-rating-style">
                  <Rating
                    onChange={(e) =>
                      state.microCourse &&
                      rateMicroCourse(
                        e as ChangeEvent<{ value: string }>,
                        state.microCourse
                      )
                    }
                    precision={0.5}
                    max={5}
                    name={`resource${state.microCourse.id}`}
                    defaultValue={state.microCourse.rating}
                    size="large"
                  />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`mailto:?subject=${
                      getLanguage() === "sv"
                        ? "Rekommenderat innehåll till dig!"
                        : "Recommended content for you!"
                    }&body=${getEmailBody(state.microCourse)}`}
                  >
                    <Share />
                  </a>
                  {state.ratingLoading && (
                    <SmallLoader
                      style={{ marginLeft: "10px" }}
                      text={t("MicroCoursePage.ratingLoading")}
                      size={20}
                    />
                  )}
                </div>

                {state.ratingError && (
                  <AlertBox
                    message={state.ratingError}
                    style={{ margin: "auto" }}
                  />
                )}
              </div>
            </div>
          </div>
        </Paper>
      )}
    </div>
  );
};
