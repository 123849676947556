import type { VFC } from "react";
import "./PreviousResultPage.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

interface Props {
  nextTermGoal: number | null;
  nextTermNumber: number;
  cycleId: string;
  surveyId: string;
}

export const NextTermGoal: VFC<Props> = ({
  nextTermGoal,
  nextTermNumber,
  surveyId,
  cycleId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      className="next-term-goal-wrapper"
      onClick={() =>
        navigate(`/surveys/result/${surveyId}/${cycleId}/from-overview`)
      }
      onKeyPress={() =>
        navigate(`/surveys/result/${surveyId}/${cycleId}/from-overview`)
      }
      role="button"
      tabIndex={-1}
    >
      {nextTermGoal && (
        <div className="goal-container">
          <p>
            {t("PreviousResultPage.yourGoal")} {nextTermNumber}
          </p>
          <div className="next-term-goal">{nextTermGoal}</div>
        </div>
      )}
      {!nextTermGoal && <p>{t("PreviousResultPage.noGoal")}</p>}
    </div>
  );
};
