import axios, { AxiosResponse } from "axios";
import client from "../httpClient";
import type {
  ApiResponseAccessTokenResource,
  ApiResponseBossesListModel,
  ApiResponseCompanyAverageModel,
  ApiResponseCounselingDataModel,
  ApiResponseCounselingResourceModel,
  ApiResponseDepartmentListModel,
  ApiResponseEmployeeModel,
  ApiResponseMicroCourseResourceModel,
  ApiResponseMicroCoursesModel,
  ApiResponseMicroCourseWithResourcesModel,
  ApiResponseNestedUserTermModel,
  ApiResponseNestedUserTermModuleActivityWithTextDataModel,
  ApiResponsePreviousUserResultsModel,
  ApiResponseResourceWithTextDataModel,
  ApiResponseSurveyModel,
  ApiResponseSurveyResultSummaryModel,
  ApiResponseTeacherModel,
  ApiResponseTitleListModel,
  ApiResponseUserCyclesWithoutQuestionsModel,
  ApiResponseUserTermGoalSettingModel,
  RateMicroCourseModel,
  ResourceRatingModel,
  UpdateUserTermGoalModel,
  UserDataRequestModel,
  UserInputModel,
} from "./apiInterfaces";

const API = `${process.env.REACT_APP_API_URL}/api`;
const API_V1 = `${API}/v1`;

/* Auth related endpoints */
const AUTH = `${API_V1}/login`;
const REVOKE = `${API_V1}/token`;

const signIn = (
  email: string,
  password: string
): Promise<AxiosResponse<ApiResponseAccessTokenResource>> =>
  axios.post(`${AUTH}/user`, { email, password });

const logout = (): Promise<AxiosResponse<void>> =>
  client.get(`${AUTH}/user/logout`, {});

const getUser = (): Promise<AxiosResponse<ApiResponseEmployeeModel>> =>
  client.get(`${API_V1}/user/me`);

const updateUser = (
  user: UserDataRequestModel
): Promise<AxiosResponse<ApiResponseEmployeeModel>> =>
  client.post(`${API_V1}/user/me`, user);

const changePassword = (
  password: string,
  passwordConfirmation: string
): Promise<AxiosResponse<void>> =>
  client.post(`${API_V1}/login/user/change-password`, {
    password,
    passwordConfirmation,
  });

const generatePasswordResetToken = (
  email: string
): Promise<AxiosResponse<void>> =>
  client.post(`${API_V1}/login/user/reset-password`, { email }, {}, false);

const resetPasswordWithToken = (
  token: string,
  newPassword: string
): Promise<AxiosResponse<void>> =>
  client.post(
    `${API_V1}/login/user/reset-password?token=${token}`,
    {
      password: newPassword,
    },
    {},
    false
  );

const refreshToken = (
  token: string,
  email: string
): Promise<AxiosResponse<ApiResponseAccessTokenResource>> =>
  axios.post(`${REVOKE}/user/refresh`, { Token: token, UserEmail: email });

/* Departments and titles related enpoints */
const getDepartments = (): Promise<
  AxiosResponse<ApiResponseDepartmentListModel>
> => client.get(`${API_V1}/user/departments`);

const getTitles = (): Promise<AxiosResponse<ApiResponseTitleListModel>> =>
  client.get(`${API_V1}/user/titles`);

const getBosses = (): Promise<AxiosResponse<ApiResponseBossesListModel>> =>
  client.get(`${API_V1}/user/bosses`);

/* Survey and cycles related endpoints */
const getCycles = (): Promise<
  AxiosResponse<Array<ApiResponseUserCyclesWithoutQuestionsModel>>
> => client.get(`${API_V1}/user/cycles`);

const getSurvey = (
  surveyId: string
): Promise<AxiosResponse<ApiResponseSurveyModel>> =>
  client.get(`${API_V1}/user/cycles/${surveyId}`);

const postSurveyUserAnswer = (
  id: string,
  answers: Array<{ id: string; value: number }>
): Promise<AxiosResponse<ApiResponseSurveyResultSummaryModel>> =>
  client.post(`${API_V1}/user/useranswer`, { id, answers });

const getUserTermAndGoalSetting = (
  cycleId: string
): Promise<AxiosResponse<ApiResponseUserTermGoalSettingModel>> =>
  client.get(`${API_V1}/user/useranswer/cycles/${cycleId}/userTerm`);

const updateNextTermGoalSettingForUser = (
  cycleId: string,
  goalSettingData: UpdateUserTermGoalModel
): Promise<AxiosResponse<void>> =>
  client.put(
    `${API_V1}/user/useranswer/cycles/${cycleId}/nextUserTermGoalSetting`,
    goalSettingData
  );

const getUserCycleResult = (
  cycleId: string
): Promise<AxiosResponse<ApiResponseSurveyResultSummaryModel>> =>
  client.get(`${API_V1}/user/useranswer/cycles/${cycleId}`);

const getUserPreviousResults = (): Promise<
  AxiosResponse<ApiResponsePreviousUserResultsModel>
> => client.get(`${API_V1}/user/useranswer/previousResults`);

const startSurvey = (cycleId: string): Promise<AxiosResponse<void>> =>
  client.post(`${API_V1}/user/useranswer/${cycleId}/start`);

const getCompanyAverageCycleResult = (
  cycleId: string
): Promise<AxiosResponse<ApiResponseCompanyAverageModel>> =>
  client.get(`${API_V1}/user/cycles/companyaverage/${cycleId}`);

/* Resource related endpoints */
const getResources = (): Promise<
  AxiosResponse<Array<ApiResponseResourceWithTextDataModel>>
> => client.get(`${API_V1}/user/resources`);

const getResource = (
  resourceId: string
): Promise<AxiosResponse<ApiResponseResourceWithTextDataModel>> =>
  client.get(`${API_V1}/user/resources/${resourceId}`);

const rateResource = (
  resourceId: string,
  data: ResourceRatingModel
): Promise<AxiosResponse<number>> =>
  client.post(`${API_V1}/user/resources/${resourceId}/rate`, data);

/* User program related endpoints */
const getUserProgram = (): Promise<
  AxiosResponse<ApiResponseNestedUserTermModel>
> => client.get(`${API_V1}/user/userprogram/me/program`);

const setCalendarInviteSent = (): Promise<AxiosResponse<void>> =>
  client.patch(`${API_V1}/user/userprogram/me/set-calendar-invite-sent`);

/* User activity related endpoints */
const getUserTermModuleActivity = (
  userTermModuleActivityId: string
): Promise<
  AxiosResponse<ApiResponseNestedUserTermModuleActivityWithTextDataModel>
> =>
  client.get(
    `${API_V1}/user/activities/user_term_module_activities/${userTermModuleActivityId}`
  );

const updateUserTermModuleActivity = (
  userTermModuleActivityId: string,
  userInput: UserInputModel
): Promise<AxiosResponse<void>> =>
  client.put(
    `${API_V1}/user/activities/user_term_module_activities/${userTermModuleActivityId}`,
    userInput
  );

const updateUserInput = (
  userTermModuleActivityId: string,
  userInput: UserInputModel
): Promise<AxiosResponse<void>> =>
  client.put(
    `${API_V1}/user/activities/user_term_module_activities/${userTermModuleActivityId}/user_input`,
    userInput
  );

/* Counseling resources controller */
const getCounselingResources = (): Promise<
  AxiosResponse<Array<ApiResponseCounselingResourceModel>>
> => client.get(`${API_V1}/user/counselingresources`);

const getCounselingResource = (
  counselingResourceId: string
): Promise<AxiosResponse<ApiResponseCounselingResourceModel>> =>
  client.get(`${API_V1}/user/counselingresources/${counselingResourceId}`);

const getCounselingData = (): Promise<
  AxiosResponse<ApiResponseCounselingDataModel>
> => client.get(`${API_V1}/user/counselingresources/counselingdata`);

/* Micro course and micro course resources */
const getMicroCourses = (): Promise<
  AxiosResponse<ApiResponseMicroCoursesModel>
> => client.get(`${API_V1}/user/microCourse`);

const getMicroCourse = (
  microCourseId: string
): Promise<AxiosResponse<ApiResponseMicroCourseWithResourcesModel>> =>
  client.get(`${API_V1}/user/microCourse/${microCourseId}`);

const getMicroCourseResource = (
  microCourseResourceId: string
): Promise<AxiosResponse<ApiResponseMicroCourseResourceModel>> =>
  client.get(`${API_V1}/user/microCourseResource/${microCourseResourceId}`);

const rateMicroCourse = (microCourseId: string, data: RateMicroCourseModel) =>
  client.post(`${API_V1}/user/microCourse/${microCourseId}/rate`, data);

/* Teachers */
const getTeachers = (): Promise<
  AxiosResponse<Array<ApiResponseTeacherModel>>
> => client.get(`${API_V1}/user/teachers`);

export default {
  signIn,
  logout,
  getUser,
  updateUser,
  changePassword,
  getDepartments,
  getTitles,
  getBosses,
  getSurvey,
  getCycles,
  postSurveyUserAnswer,
  startSurvey,
  generatePasswordResetToken,
  resetPasswordWithToken,
  getUserCycleResult,
  getCompanyAverageCycleResult,
  getResources,
  getResource,
  rateResource,
  getUserProgram,
  getUserTermModuleActivity,
  updateUserTermModuleActivity,
  updateUserInput,
  refreshToken,
  getCounselingResources,
  getCounselingResource,
  getCounselingData,
  setCalendarInviteSent,
  getMicroCourses,
  getMicroCourse,
  rateMicroCourse,
  getMicroCourseResource,
  getUserPreviousResults,
  getTeachers,
  getUserTermAndGoalSetting,
  updateNextTermGoalSettingForUser,
};
